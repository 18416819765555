<template>
  <div>
    <CampanhasListaUnica></CampanhasListaUnica>
  </div>

</template>

<script>
import BaseView from "@/template/BaseView.vue";
import CampanhasListaUnica from "@/components/Campanhas/CampanhasListaUnica.vue";

export default {
  components: {
    BaseView,
    CampanhasListaUnica,
  },
  created() {
    if (this.$store.getters.user.user.level == 'seller' || this.$store.getters.user.user.level == 'gerent_seller') {
      this.$router.push("/dynamicRoute/AccessDenied")};
  },
};
</script>
