let pageBasec = [
  [
    {
      type: "colun",
      name: "Três colunas",
      icon: "coluns",
      component_type: "column",
      propsRow: {
        custom: { c_class: "", c_id: "" },
        equalize: false,
        attribute_und: "Nenhuma",
        attribute_animation: "",
        duration: 1,
        delay: 0,
        width_type: "Largura Completa",
        width_fixed_und: "px",
        width_fixed: 1200,
        width_fixed_number: 100,
        width_fixed_content_type: "Fixa",
        content: {
          desktop: { und: "px", width: 1200, width_number: 80 },
          tablet: { und: "px", width: 600, width_number: 80 },
          mobile: { und: "px", width: 320, width_number: 80 },
        },
        height_und: "Altura padrão",
        height_type: "",
        min_height: {
          desktop: {
            height_min_und: "px",
            height_min: 500,
            height_min_number: 50,
          },
          tablet: {
            height_min_und: "px",
            height_min: 500,
            height_min_number: 50,
          },
          mobile: {
            height_min_und: "px",
            height_min: 500,
            height_min_number: 50,
          },
        },
        height_align_vertical: "Centralizado",
        backgd_und: "Nenhum",
        backgd: "background:#0000",
        backgd_solid: "#ffffff",
        backgd_gradient_type: "linear-gradient",
        backgd_gradient_deg: "0",
        backgd_gradient_c1: "#FFFFFF",
        backgd_gradient_c2: "#FFFFFF",
        backgd_image:
          "https://gdigital.s3.amazonaws.com/gdigital/1/background%20%281%29.webp",
        backgd_image_repeat_und: "Nenhuma",
        backgd_image_repeat_type: "no-repeat",
        backgd_image_position_und: "Centralizado",
        backgd_image_position_type: "center center",
        backgd_anexacao_und: "Deslizar",
        backgd_anexacao: "scroll",
        backgd_escaca_und: "Preencher",
        backgd_escaca_type: "cover",
        backgd_overlap_und: "Nenhuma",
        backgd_overlap: "",
        backgd_overlap_cor: "transparent",
        backgd_overlap_type: "linear-gradient",
        backgd_overlap_deg: "0",
        backgd_overlap_c1: "transparent",
        backgd_overlap_c2: "transparent",
        border_und: "Nenhuma",
        border_type: "",
        border: "border:none",
        border_color: "#000000",
        border_px_top: 2,
        border_px_right: 2,
        border_px_bottom: 2,
        border_px_left: 2,
        border_radius_top: 0,
        border_radius_right: 0,
        border_radius_bottom: 0,
        border_radius_left: 0,
        border_shadow_color: "#000000",
        border_shadow_x: 0,
        border_shadow_y: 0,
        border_shadow_blur: 0,
        border_shadow_spread: 0,
        mg_top: 0,
        mg_right: 0,
        mg_bottom: 0,
        mg_left: 0,
        pd_top: 0,
        pd_right: 0,
        pd_bottom: 0,
        pd_left: 0,
        monitor: true,
        tablet: true,
        phone: true,
        default_border: true,
        spacing: {
          desktop: {
            margin: "0px",
            padding: "",
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            padding_top: 0,
            padding_right: 0,
            padding_bottom: 0,
            padding_left: 0,
          },
          tablet: {
            margin: "0px",
            padding: "",
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            padding_top: 0,
            padding_right: 0,
            padding_bottom: 0,
            padding_left: 0,
          },
          mobile: {
            margin: "0px",
            padding: "",
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            padding_top: 0,
            padding_right: 0,
            padding_bottom: 0,
            padding_left: 0,
          },
        },
        height: "height:auto",
      },
      coluns: [
        [
          {
            empty: true,
            propsColumn: {
              custom: { c_class: "", c_id: "" },
              width: 33.33,
              width_division: 100,
              width_equalizar_und: "Não",
              height: {
                desktop: { height: "100", height_und: "px" },
                tablet: { height: "100", height_und: "px" },
                mobile: { height: "100", height_und: "px" },
              },
              alignment: "center",
              backgd_und: "Nenhum",
              backgd: "background:transparent",
              backgd_solid: "#FFFFFF00",
              backgd_gradient_type: "linear-gradient",
              backgd_gradient_deg: "0",
              backgd_gradient_c1: "#FFFFFF",
              backgd_gradient_c2: "#FFFFFF",
              backgd_image:
                "https://gdigital.s3.amazonaws.com/gdigital/1/background.webp",
              backgd_image_repeat_und: "Nenhuma",
              backgd_image_repeat_type: "no-repeat",
              backgd_image_position_und: "Centralizado",
              backgd_image_position_type: "center center",
              backgd_anexacao_und: "Deslizar",
              backgd_anexacao: "scroll",
              backgd_escaca_und: "Preencher",
              backgd_escaca_type: "cover",
              backgd_overlap_und: "Nenhuma",
              backgd_overlap: "",
              backgd_overlap_cor: "transparent",
              backgd_overlap_type: "linear-gradient",
              backgd_overlap_deg: "0",
              backgd_overlap_c1: "transparent",
              backgd_overlap_c2: "transparent",
              border_default: true,
              border_und: "Nenhuma",
              border_type: "",
              border: "border:none",
              border_color: "#000000",
              border_px_top: 2,
              border_px_right: 2,
              border_px_bottom: 2,
              border_px_left: 2,
              border_radius_top: 2,
              border_radius_right: 0,
              border_radius_bottom: 0,
              border_radius_left: 0,
              border_shadow_color: "#000000",
              border_shadow_x: 0,
              border_shadow_y: 0,
              border_shadow_blur: 0,
              border_shadow_spread: 0,
              margin: "margin:0px",
              padding: "padding:0px",
              spacing: {
                desktop: {
                  margin_top: 0,
                  margin_right: 0,
                  margin_bottom: 0,
                  margin_left: 0,
                  padding_top: 0,
                  padding_right: 0,
                  padding_bottom: 0,
                  padding_left: 0,
                },
                tablet: {
                  margin_top: 0,
                  margin_right: 0,
                  margin_bottom: 0,
                  margin_left: 0,
                  padding_top: 0,
                  padding_right: 0,
                  padding_bottom: 0,
                  padding_left: 0,
                },
                mobile: {
                  margin_top: 0,
                  margin_right: 0,
                  margin_bottom: 0,
                  margin_left: 0,
                  padding_top: 0,
                  padding_right: 0,
                  padding_bottom: 0,
                  padding_left: 0,
                },
              },
              attribute_und: "Nenhuma",
              attribute_animation: "",
              duration: 1,
              delay: 0,
              visibility: { tablet: true, phone: true, monitor: true },
            },
          },
        ],
        [
          {
            empty: true,
            propsColumn: {
              custom: { c_class: "", c_id: "" },
              width: 33.33,
              width_division: 100,
              width_equalizar_und: "Não",
              height: {
                desktop: { height: "100", height_und: "px" },
                tablet: { height: "100", height_und: "px" },
                mobile: { height: "100", height_und: "px" },
              },
              alignment: "center",
              backgd_und: "Nenhum",
              backgd: "background:transparent",
              backgd_solid: "#FFFFFF00",
              backgd_gradient_type: "linear-gradient",
              backgd_gradient_deg: "0",
              backgd_gradient_c1: "#FFFFFF",
              backgd_gradient_c2: "#FFFFFF",
              backgd_image:
                "https://gdigital.s3.amazonaws.com/gdigital/1/background.webp",
              backgd_image_repeat_und: "Nenhuma",
              backgd_image_repeat_type: "no-repeat",
              backgd_image_position_und: "Centralizado",
              backgd_image_position_type: "center center",
              backgd_anexacao_und: "Deslizar",
              backgd_anexacao: "scroll",
              backgd_escaca_und: "Preencher",
              backgd_escaca_type: "cover",
              backgd_overlap_und: "Nenhuma",
              backgd_overlap: "",
              backgd_overlap_cor: "transparent",
              backgd_overlap_type: "linear-gradient",
              backgd_overlap_deg: "0",
              backgd_overlap_c1: "transparent",
              backgd_overlap_c2: "transparent",
              border_default: true,
              border_und: "Nenhuma",
              border_type: "",
              border: "border:none",
              border_color: "#000000",
              border_px_top: 2,
              border_px_right: 2,
              border_px_bottom: 2,
              border_px_left: 2,
              border_radius_top: 0,
              border_radius_right: 0,
              border_radius_bottom: 0,
              border_radius_left: 0,
              border_shadow_color: "#000000",
              border_shadow_x: 0,
              border_shadow_y: 0,
              border_shadow_blur: 0,
              border_shadow_spread: 0,
              margin: "margin:0px",
              padding: "padding:0px",
              spacing: {
                desktop: {
                  margin_top: 0,
                  margin_right: 0,
                  margin_bottom: 0,
                  margin_left: 0,
                  padding_top: 0,
                  padding_right: 0,
                  padding_bottom: 0,
                  padding_left: 0,
                },
                tablet: {
                  margin_top: 0,
                  margin_right: 0,
                  margin_bottom: 0,
                  margin_left: 0,
                  padding_top: 0,
                  padding_right: 0,
                  padding_bottom: 0,
                  padding_left: 0,
                },
                mobile: {
                  margin_top: 0,
                  margin_right: 0,
                  margin_bottom: 0,
                  margin_left: 0,
                  padding_top: 0,
                  padding_right: 0,
                  padding_bottom: 0,
                  padding_left: 0,
                },
              },
              attribute_und: "Nenhuma",
              attribute_animation: "",
              duration: 1,
              delay: 0,
              visibility: { tablet: true, phone: true, monitor: true },
            },
          },
        ],
        [
          {
            empty: true,
            propsColumn: {
              custom: { c_class: "", c_id: "" },
              width: 33.33,
              width_division: 100,
              width_equalizar_und: "Não",
              height: {
                desktop: { height: "100", height_und: "px" },
                tablet: { height: "100", height_und: "px" },
                mobile: { height: "100", height_und: "px" },
              },
              alignment: "center",
              backgd_und: "Nenhum",
              backgd: "background:transparent",
              backgd_solid: "#FFFFFF00",
              backgd_gradient_type: "linear-gradient",
              backgd_gradient_deg: "0",
              backgd_gradient_c1: "#FFFFFF",
              backgd_gradient_c2: "#FFFFFF",
              backgd_image:
                "https://gdigital.s3.amazonaws.com/gdigital/1/background.webp",
              backgd_image_repeat_und: "Nenhuma",
              backgd_image_repeat_type: "no-repeat",
              backgd_image_position_und: "Centralizado",
              backgd_image_position_type: "center center",
              backgd_anexacao_und: "Deslizar",
              backgd_anexacao: "scroll",
              backgd_escaca_und: "Preencher",
              backgd_escaca_type: "cover",
              backgd_overlap_und: "Nenhuma",
              backgd_overlap: "",
              backgd_overlap_cor: "transparent",
              backgd_overlap_type: "linear-gradient",
              backgd_overlap_deg: "0",
              backgd_overlap_c1: "transparent",
              backgd_overlap_c2: "transparent",
              border_default: true,
              border_und: "Nenhuma",
              border_type: "",
              border: "border:none",
              border_color: "#000000",
              border_px_top: 2,
              border_px_right: 2,
              border_px_bottom: 2,
              border_px_left: 2,
              border_radius_top: 0,
              border_radius_right: 0,
              border_radius_bottom: 0,
              border_radius_left: 0,
              border_shadow_color: "#000000",
              border_shadow_x: 0,
              border_shadow_y: 0,
              border_shadow_blur: 0,
              border_shadow_spread: 0,
              margin: "margin:0px",
              padding: "padding:0px",
              spacing: {
                desktop: {
                  margin_top: 0,
                  margin_right: 0,
                  margin_bottom: 0,
                  margin_left: 0,
                  padding_top: 0,
                  padding_right: 0,
                  padding_bottom: 0,
                  padding_left: 0,
                },
                tablet: {
                  margin_top: 0,
                  margin_right: 0,
                  margin_bottom: 0,
                  margin_left: 0,
                  padding_top: 0,
                  padding_right: 0,
                  padding_bottom: 0,
                  padding_left: 0,
                },
                mobile: {
                  margin_top: 0,
                  margin_right: 0,
                  margin_bottom: 0,
                  margin_left: 0,
                  padding_top: 0,
                  padding_right: 0,
                  padding_bottom: 0,
                  padding_left: 0,
                },
              },
              attribute_und: "Nenhuma",
              attribute_animation: "",
              duration: 1,
              delay: 0,
              visibility: { tablet: true, phone: true, monitor: true },
            },
          },
        ],
      ],
      id: "ed6c",
    },
  ],
];
export default pageBasec;