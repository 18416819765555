<template>
  <BaseModal
    name="Modal-add-integration"
    id="Modal-add-integration"
    idModal="Modal-add-integration"
    size="lg"
    title="Greenn"
    @shown="openModal"
  >
    <div class="container-flex">
      <div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            width: 100%;
          "
        >
          <div style="width: 90%">
            <label for="">Nome da integração</label>

            <b-form-input
              type="text"
              v-model="title"
              class="inputs_modal_tenant_domain"
              placeholder="Nome da Integração"
            />
            <span class="titleError text-danger" v-show="titleRequired"
              >Título obrigatório</span
            >
            <span class="titleError text-danger" v-if="titleSpace"
              >Espaços inválidos</span
            >
            <span
              class="titleError text-danger"
              v-show="titleLength"
              v-if="title.length < 6 || title.length > 40"
              >Mínimo de 6 caracteres e máximo de 40 caracteres</span
            >
          </div>
          <div id="product-multiselect" style="width: 100%">
            <label for="">Selecione o produto</label>
            <multiselect
              :options="products"
              v-model="product"
              dropdown
              :searchable="true"
              :show-labels="false"
              :hide-selected="true"
              :allow-empty="false"
              placeholder="Selecione um produto"
              open-direction="bottom"
              label="name"
              track-by="id"
              style="width: 100%; z-index: 999"
            ></multiselect>
          </div>
        </div>

        <label for="">Quando acontecer o(s) evento(s)</label><br />
        <div style="width: 100%">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
            "
          >
            <div
              class="container"
              style="width: 100%; position: relative; left: 20px"
            >
              <label for="" style="width: 100%; margin-left: -10px"
                >Vendas</label
              >

              <!-- Venda Paga -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.sale_paid"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="sale_paid"
                  style="width: 0px; white-space: nowrap"
                >
                  Venda paga
                </p>
              </b-form-checkbox>
              <!-- Venda Recusada -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.sale_declined"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="sale_declined"
                  style="width: 0px; white-space: nowrap"
                >
                  Venda recusada
                </p>
              </b-form-checkbox>
              <!-- Venda Reembolsada -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.sale_refunded"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="sale_refunded"
                  style="width: 0px; white-space: nowrap"
                >
                  Venda reembolsada
                </p>
              </b-form-checkbox>
              <!-- Venda Chargeback-->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.chargeback_processed"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="chargeback_processed"
                  style="width: 0px; white-space: nowrap"
                >
                  Chargeback realizado
                </p>
              </b-form-checkbox>
              <!-- Venda Não Paga -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.sale_unpaid"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="sale_unpaid"
                  style="width: 0px; white-space: nowrap"
                >
                  Venda não paga
                </p>
              </b-form-checkbox>

              <!-- Venda aguardando pagamento -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.sale_awaiting_payment"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="sale_awaiting_payment"
                  style="width: 0px; white-space: nowrap"
                >
                  Venda aguardando pagamento
                </p>
              </b-form-checkbox>
            </div>

            <div class="container" style="width: 100%">
              <label for="" style="width: 100%; margin-left: -10px"
                >Assinatura</label
              >
              <!-- Venda Paga -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.unpaid_subscription"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="unpaid_subscription"
                  style="width: 0px; white-space: nowrap"
                >
                  Assinatura não paga
                </p>
              </b-form-checkbox>
              <!-- Venda Recusada -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.paid_subscription"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="paid_subscription"
                  style="width: 0px; white-space: nowrap"
                >
                  Assinatura paga
                </p>
              </b-form-checkbox>
              <!-- Venda Reembolsada -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.trial_subscription"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="trial_subscription"
                  style="width: 0px; white-space: nowrap"
                >
                  Assinatura em trial
                </p>
              </b-form-checkbox>
              <!-- Venda Chargeback-->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.subscription_awaiting_payment"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="subscription_awaiting_payment"
                  style="width: 0px; white-space: nowrap"
                >
                  Assinatura aguardando pagamento
                </p>
              </b-form-checkbox>
              <!-- Venda Não Paga -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.subscription_completed"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="subscription_completed"
                  style="width: 0px; white-space: nowrap"
                >
                  Assinatura finalizada
                </p>
              </b-form-checkbox>

              <!-- Venda aguardando pagamento -->
              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.subscription_canceled"
                style="padding: 0px 0px 0px 0px; margin-left: 30px"
              >
                <p
                  class="info-checkbox"
                  ref="subscription_canceled"
                  style="width: 0px; white-space: nowrap"
                >
                  Assinatura cancelada
                </p>
              </b-form-checkbox>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: 10px;
              width: 100%;
            "
          >
            <div style="width: 100%">
              <label for="" style="width: 100%; margin-left: 7px"
                >Checkout</label
              >

              <b-form-checkbox
                class="lead-switch"
                name="is_main"
                size="lg"
                switch
                v-model="options.checkout"
                style="padding: 0px 0px 0px 0px; margin-left: 50px"
              >
                <p
                  ref="checkout"
                  class="info-checkbox"
                  style="width: 0px; white-space: nowrap"
                >
                  {{ options.checkout ? "CheckOut" : "CheckOut abandonado" }}
                </p>
              </b-form-checkbox>
            </div>
          </div>
          <div class="add-link-body">
            <label>Formulário</label>
            <router-link
              v-if="!forms.length && verificUserLevel"
              :to="`/dynamicRoute/funis/${this.$route.params.id}/criar-formulario`"
              class="add-link"
              style="margin-top: -7px"
              >Deseja criar um formulário?</router-link
            >
          </div>

          <BaseSelect
            :selectModel="form_id"
            placeholder="Selecione um formulário"
            track-by="id"
            trackname="title"
            :array="forms"
            style="width: 100%"
            selectLabel=""
            :searchable="true"
            :loading="loading_select"
            deselectLabel=""
            direction="top"
            @search="debounceForms($event)"
            @callback="getForms($event)"
            @change="form_id = $event"
            ><span slot="noResult">Nenhum formulário encontrado.</span>
          </BaseSelect>
        </div>
      </div>
    </div>

    <template v-slot:footer="{}">
      <BaseButton variant="link-info" class="mr-4" @click="closeModal">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" class="mr-4" @click="create">
        Criar
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import Multiselect from "vue-multiselect";

import ProductIntegrationService from "@/services/resources/ProductIntegrationService";
const serviceProductIntegration = ProductIntegrationService.build();
//
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
//
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      titleRequired: false,
      titleLength: false,
      titleSpace: false,
      products: [],
      options: {
        sale_paid: false,
        sale_declined: false,
        sale_refunded: false,
        chargeback_processed: false,
        sale_unpaid: false,
        sale_awaiting_payment: false,
        unpaid_subscription: false,
        paid_subscription: false,
        trial_subscription: false,
        subscription_awaiting_payment: false,
        subscription_completed: false,
        subscription_canceled: false,
        checkout: false,
      },
      form_id: { id: null, title: "Selecione um formulario" },
      product: [],
      product_greenn_id: null,
      title: "",
      forms: [],
      loading_select: false,
      search: "",
      stops: [],
      stopForm: false,
      antiDuplicate: "",
    };
  },

  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    debounceForms: _.debounce(function (query) {
      if (query === "") {
        this.loading_select = false;
      } else {
        this.search = query;
        // this.forms = [];
        this.getForms(this.page, query);
      }
    }, 500),

    getForms(page = 1, query = "") {
      if (this.stopForm == true) {
        this.loading_select = false;
        return;
      }
      var data = {
        id: `?page=${page}&campaign_id=${this.$route.params.id}&status=active&order_by=title&order=ASC&search=${query}`,
      };
      serviceForm
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopForm = true;
          }
          this.forms = this.forms.concat(resp.data);
          this.forms = this.forms.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
          console.log("forms", this.forms);
        })
        .catch((error) => {});
    },

    closeModal() {
      this.options.sale_paid = false;
      this.options.sale_declined = false;
      this.options.sale_refunded = false;
      this.options.chargeback_processed = false;
      this.options.sale_unpaid = false;
      this.options.sale_awaiting_payment = false;
      this.options.unpaid_subscription = false;
      this.options.paid_subscription = false;
      this.options.trial_subscription = false;
      this.options.subscription_awaiting_payment = false;
      this.options.subscription_completed = false;
      this.options.subscription_canceled = false;
      this.options.checkout = false;
      this.form_id = { id: null, title: "Selecione um formulario" };
      this.product_greenn_id = null;
      this.title = "";
      this.product = [];
      (this.titleRequired = false),
        (this.titleLength = false),
        (this.titleSpace = false),
        this.$bvModal.hide("Modal-add-integration");
    },
    openModal() {
      this.getProducts();
    },
    validSpace() {
      const regex = /  /;
      this.titleSpace = regex.test(this.title);
    },
    create() {
      let form;
      if (this.form_id) {
        form = this.form_id.id;
      }
      let data = {
        title: this.title,
        product_greenn_id: this.product.id,
        product_name: this.product.name,
        tenant_id: this.$store.getters.user.user.tenant_id,
        actions: this.options,
        form_id: form,
        campaign_id: this.$route.params.id,
      };

      var teste = Object.keys(this.options);
      let options = 0;
      for (let i = 0; i < teste.length; i++) {
        if (this.options[teste[i]] == true) {
          options = options + 1;
        }
      }
      // if (!this.title) {
      //   this.$grToast.toast("Escreva um nome!", {
      //     title: "Integração de Produtos",
      //     variant: "danger",
      //     autoHideDelay: 5000,s
      //     appendToast: true,
      //   });
      //   return;
      // }
      const regex = /  /;
      if (!this.title) {
        this.titleRequired = true;
        this.titleLength = false;
      } else if (
        this.title.length < 6 ||
        this.title.length > 40 ||
        regex.test(this.title)
      ) {
        this.titleLength = true;
        this.titleRequired = false;
      } else {
        this.titleRequired = false;
        this.titleLength = false;
      }
      if (!this.product.name) {
        this.$grToast.toast("Selecione um produto!", {
          title: "Integração de Produtos",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (options == 0) {
        this.$grToast.toast("Selecione um ou mais eventos!", {
          title: "Integração de Produtos",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      serviceProductIntegration
        .create(data)
        .then((resp) => {
          if (resp) {
            this.$grToast.toast(resp.message, {
              title: "Integração de Produtos",
              variant: resp.color,
              autoHideDelay: 5000,
              appendToast: true,
            });
            if (resp.success === false) {
              setTimeout(() => {
                resp.actions.forEach((item_) => {
                  this.$refs[item_].style.color = "";
                });
              }, 3500);

              resp.actions.forEach((item_) => {
                this.$refs[item_].style.color = "red";
              });
              return;
            }

            this.options.sale_paid = false;
            this.options.sale_declined = false;
            this.options.sale_refunded = false;
            this.options.chargeback_processed = false;
            this.options.sale_unpaid = false;
            this.options.sale_awaiting_payment = false;
            this.options.unpaid_subscription = false;
            this.options.paid_subscription = false;
            this.options.trial_subscription = false;
            this.options.subscription_awaiting_payment = false;
            this.options.subscription_completed = false;
            this.options.subscription_canceled = false;
            this.options.checkout = false;
            this.form_id = null;
            this.product_greenn_id = 0;
            this.title = "";
            this.product = [];
            this.form_id = null;
            this.$bvModal.hide("Modal-add-integration");
            this.$parent.getProductIntegration();
          }
        })
        .catch((r) => {
          // console.log("error", r);
        });
    },
    getProducts() {
      serviceProductIntegration.read("greenn").then((resp) => {
        this.products = resp;
      });
    },
  },

  watch: {
    title: "validSpace",
  },
};
</script>

<style lang="scss" scoped>
.container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
.container-flex div {
  display: flex;
  margin-bottom: 0.5em;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  div {
    width: 35%;
    height: 35%;
  }
}
.text-center {
  text-align: center;
  gap: 10px;
}

.align-center {
  align-items: center;
}
.titleError {
  font-size: 12px;
  font-weight: 500;
  color: #dc3545 !important;
  margin-top: 5px;
}
</style>
