<template>
  <BaseModal
    name="edicao-rapida"
    id="edicao-rapida"
    idModal="edicao-rapida"
    size="xl"
    title="Edição Rápida"
    @shown="openModal"
  >
    <div v-if="!loading">
      <div class="top-filter">
        <div>
          <p class="ml-1 mb-3">Configure sua página abaixo:</p>
        </div>
      </div>

      <div>
        <b-tabs
          class="mt-4"
          ref="tabs"
          nav-class="TabHeader no-border"
          active-nav-item-class="TabHeader--active"
          :no-nav-style="true"
        >
          <b-tab :title="`Dados da página`" active>
            <b-container
              fluid
              class="mt-1 wrapper container-user page-body"
              v-show="!loading"
            >
              <div class="grid-inputs" style="padding-top: 10px">
                <b-form>
                  <b-form-group label="Título">
                    <b-form-input v-model="pagina.titulo"></b-form-input>
                  </b-form-group>
                </b-form>
                <b-form>
                  <div class="add-link-body">
                    <label class="mr-1">Selecione o domínio</label>
                    <router-link
                      v-if="!dominioOptions.length && stop && verificUserLevel"
                      :to="{ name: 'Dominios' }"
                      class="add-link"
                      >Deseja criar um domínio?</router-link
                    >
                  </div>
                  <!-- <BaseSelect
                    id="ajax"
                    :selectModel="pagina.dominio"
                    label=""
                    placeholder="Selecionar domínio"
                    track-by="id"
                    :array="dominioOptions"
                    :multiple="false"
                    trackname="domain"
                    :taggable="true"
                    :searchable="true"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    @select="selectDomain"
                    @callback="getDomain($event)"
                    @change="pagina.dominio = $event"
                    ><span slot="noResult"
                      >Oops! Nenhum domínio encontrado.</span
                    >
                  </BaseSelect> -->

                  <BaseSelect
                    :selectModel="pagina.dominio"
                    id="ajax"
                    trackname="domain"
                    track-by="id"
                    placeholder="Selecione um domínio"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    open-direction="bottom"
                    :array="dominioOptions"
                    :multiple="false"
                    :searchable="true"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-no-results="true"
                    :hide-selected="false"
                    :watch="true"
                    @callback="fetchDomains($event)"
                    @change="pagina.dominio = $event"
                    :loading="loadingDomains"
                    @search="debounceDomains($event)"
                  >
                    <span slot="noResult"
                      >Oops! Nenhum domínio encontrado.</span
                    >
                  </BaseSelect>
                </b-form>
                <b-form>
                  <b-form-group label="Link">
                    <b-form-input v-model="pagina.link"></b-form-input>
                  </b-form-group>
                </b-form>
                <b-form>
                  <b-form-group label="Status">
                    <multiselect
                      v-model="pagina.status"
                      id="ajax"
                      label="text"
                      track-by="value"
                      placeholder="Selecionar domínio"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="bottom"
                      :options="statusOptions"
                      :multiple="false"
                      :searchable="false"
                      :loading="loading"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :show-no-results="true"
                      :hide-selected="false"
                      @input="pageStatus"
                    >
                      <span slot="noResult"
                        >Oops! Nenhum domínio encontrado.</span
                      >
                    </multiselect>
                  </b-form-group>
                </b-form>
                <b-form-checkbox
                  v-model="allowCopy"
                  name="is_main"
                  size="lg"
                  switch
                >
                  <p class="info-checkbox">
                    Permitir clonagem: 
                    {{ allowCopy == true || allowCopy == "true" ? " Ativo " : " Inativo " }}
                  </p>
                </b-form-checkbox>
                <a class="decoration-none" :href="hefsQrcode" download="qrCodeToPage.png">
                  <div class="feke-base-button" variant="primary">
                    <div class="content-feke-button">
                      <svg  xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,132.69V40a8,8,0,0,0-16,0v92.69L93.66,106.34a8,8,0,0,0-11.32,11.32Z">            
                        </path>
                      </svg>                 
                        <span style="color: #fff;">Exportar QR Code</span> 
                    </div>                   
                  </div>
                </a>
              </div>
            </b-container>
          </b-tab>
          <b-tab :title="`Pixels trackeamento`">
            <b-container
              fluid
              class="mt-1 wrapper container-user"
              v-show="!loading"
            >
              <div class="grid-inputs" style="padding-top: 10px">
                <div style="display: grid; gap: 5px">
                  <b-form-group label="CSS Customizado">
                    <b-form-textarea
                      v-model="dynamic_css"
                      placeholder="Digite aqui o CSS customizado da página"
                      no-resize
                      style="height: 200px !important"
                      @input="validCss($event)"
                      :class="{ notValid: errorCss }"
                    ></b-form-textarea>
                  </b-form-group>
                  <span v-if="errorCss" class="message-error"
                    >Aviso: uso de tags não é permitida neste campo de
                    texto.</span
                  >
                </div>
                <div style="display: grid; gap: 5px">
                  <b-form-group label="JavaScript Customizado">
                    <b-form-textarea
                      v-model="dynamic_js"
                      placeholder="Digite aqui o JavaScript customizado da página"
                      no-resize
                      style="height: 200px !important"
                      @input="validJs($event)"
                      :class="{ notValid: errorJs }"
                    ></b-form-textarea>
                  </b-form-group>
                  <span v-if="errorJs" class="message-error"
                    >Aviso: uso de tags não é permitida neste campo de
                    texto.</span
                  >
                </div>
              </div>
              <br />
              <b-form>
                <b-form-group label="">
                  <b-row>
                    <b-col cols="6">
                      <div class="add-link-body">
                        <label class="mr-1">Pixel</label>
                        <router-link
                          v-if="
                            !pixelOptions.length && stop2 && verificUserLevel
                          "
                          :to="{ name: 'Pixel' }"
                          class="add-link"
                          >Deseja criar um pixel?</router-link
                        >
                      </div>
                      <BaseSelect
                        id="ajax"
                        :selectModel="pixel"
                        placeholder="Selecionar pixel"
                        trackname="title"
                        track-by="id"
                        :array="pixelOptions"
                        :multiple="true"
                        :taggable="true"
                        noResult="Oops! Nenhum pixel encontrado."
                        selectLabel=""
                        deselectLabel=""
                        selectedLabel=""
                        direction="top"
                        @select="savePixelsPage($event)"
                        @callback="fetchPixels($event)"
                        @change="pixel = $event"
                        @search="debouncePixel"
                        :loading="loadingPixel"
                        :watch="true"
                        :selectEvent="true"
                      >
                      </BaseSelect>
                    </b-col>
                    <b-col
                      cols="6"
                      class="mb-3"
                      style="overflow-y: auto; height: 130px;"
                    >
                      <div v-for="(adsPixel, index) in getGoogleAdsPixels" :key="index">
                        <label for="pixel_id"
                        >Label de conversão -
                        {{
                          adsPixel.title && adsPixel.title.length > 18
                            ? adsPixel.title.substr(0, 18) + "..."
                            : adsPixel.title
                        }}
                        (opcional)
                      </label>
                      <input style="width: 100%" type="text" id="pixel_id" v-model="adsPixel.conversion_label"/>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-form>
            </b-container>
          </b-tab>
          <b-tab :title="`Redirecionamento`">
            <b-container
              fluid
              class="mt-1 wrapper container-user"
              v-show="!loading"
            >
              <div class="grid-inputs">
                <div>
                  <div style="padding: 10px 0 20px 0">
                    <b-form-checkbox
                      v-model="time_redirect"
                      name="is_main"
                      size="lg"
                      switch
                      @change="time_redirect ? (data_redirect = false) : ''"
                    >
                      <p class="info-checkbox">
                        Redirecionamento por Tempo:
                        {{ time_redirect ? " Ativo " : "Inativo" }}
                      </p>
                    </b-form-checkbox>
                  </div>
                  <b-form-group
                    label="Digite o tempo, em segundos, para redirecionar a página"
                  >
                    <b-form-input
                      v-model="redirect_time"
                      type="number"
                      placeholder="Ex: 20"
                    ></b-form-input>
                  </b-form-group>
                  <br />
                </div>
                <div>
                  <div style="padding: 10px 0 20px 0">
                    <b-form-checkbox
                      v-model="data_redirect"
                      name="is_main"
                      size="lg"
                      switch
                      @change="data_redirect ? (time_redirect = false) : ''"
                    >
                      <p class="info-checkbox">
                        Redirecionamento por Data:
                        {{ data_redirect ? " Ativo " : "Inativo" }}
                      </p>
                    </b-form-checkbox>
                  </div>
                  <b-form-group label="A página ficara inativa após esta data">
                    <date-range-picker
                      ref="picker"
                      opens="left"
                      :locale-data="localeData"
                      :showWeekNumbers="false"
                      :showDropdowns="true"
                      :autoApply="false"
                      :ranges="false"
                      :timePicker24Hour="true"
                      :timePicker="true"
                      v-model="dateRange1"
                      :single-date-picker="true"
                      class="w-100"
                    >
                      <template
                        v-if="dateRange1.startDate && dateRange1.endDate"
                        v-slot:input="picker1"
                      >
                        {{ picker1.startDate | datetimem }}
                      </template>
                    </date-range-picker>
                  </b-form-group>
                </div>
              </div>
              <b-form-group
                class="mt-3"
                label="Insira a página de redirecionamento"
              >
                <b-form-input
                  ref="url"
                  v-model="redirect_url"
                  placeholder="Ex: https://greenn.com.br/"
                ></b-form-input>
              </b-form-group>
            </b-container>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="outline-danger" class="mr-4" @click="deletaViews">
        Zerar estatísticas
      </BaseButton>
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import PageService from "@/services/resources/PageService";
const servicePage = PageService.build();

import PixelService from "@/services/resources/PixelService";
const servicePixel = PixelService.build();

import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();

import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();

import Multiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import Vue from "vue";

import _ from "lodash";

export default {
  props: ["editPage"],
  components: {
    Multiselect,
    DateRangePicker,
  },
  data() {
    return {
      hefsQrcode:'',
      pagePixel: "",
      loadingPixel: false,
      searchPixel: "",
      stop: false,
      stop2: false,
      stopDomain: false,
      pageDomain: 1,
      loadingDomains: false,
      errorCss: false,
      errorJs: false,
      dynamic_js: "",
      dynamic_css: "",
      protect_redirect: "",
      paginaOptions: [],
      campanha: null,

      url_block: "",
      data_redirect: false,
      time_redirect: false,
      allowCopy: false,
      redirect_time: "",
      redirect_url: "",
      protection: false,
      templates: [],
      html_header: "",
      html_body: "",
      pagina: {
        titulo: "",
        dominio: "",
        link: "",
        status: "publish",
        time: "",
      },
      dateRange1: {
        startDate: moment(),
        endDate: moment(),
      },
      statusOptions: [
        { value: "publish", text: "Publicada" },
        { value: "draft", text: "Rascunho" },
      ],
      dominioOptions: [],
      pixelOptions: [],
      pixel: [],

      loading: false,
      count: 1,
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
    };
  },
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    user_key() {
      return this.$store.getters.user.tokenUsuario;
    },
    domain() {
      return this.$store.getters.user.blog.domain;
    },
    user_id() {
      return this.$store.getters.user.user.id;
    },
    getGoogleAdsPixels() {
      return this.pixel.filter((x) => x.type === "GOOGLEADWORDS" && x.conversion);
    },
  },
  watch: {
    campanha() {
      this.fetchPageListService();
    },
    "pagina.link":{
      handler: "debounceUrlQrCode",
    },
    "pagina.dominio":{
      handler: "debounceUrlQrCode",
    },
  },
  methods: {
    debounceUrlQrCode: _.debounce(function(){
      this.downloadImg()
    },500),
    getDomain(){
      if(this.pagina.dominio.status == 6 && this.pagina.dominio.ssl_status == 'ready'){
        return`https://${this.pagina.dominio.domain}/${this.removeAccents(this.pagina.link)}`
      }
      return `https://${this.$store.getters.tenantSubdomain}.gdigital.com.br/${this.removeAccents(this.pagina.link)}`
    },
    async downloadImg(){

      const qrApiBaseUrl = "https://api.qrserver.com/v1/create-qr-code/";
      let dominio = await this.getDomain()

      const qrCodeUrl = `${qrApiBaseUrl}?size=240x240&data=${encodeURIComponent(
        `${dominio}`
        )}`;

        let response = await axios.get(qrCodeUrl, { responseType: 'blob' })

        this.hefsQrcode = window.URL.createObjectURL(new Blob([response.data]))
    },
    fetchDomains(page, query = "") {
      if(this.stopDomain){
        return;
      }
      var data = {
        id: `list?page=${page ? page : 1}&order_by=domain&order=ASC&search=${query}`,
      };

      this.loadingDomains = true;

      serviceDomain
        .read(data)
        .then((resp) => {
          this.loadingDomains = false;
          if (resp.data.length < resp.per_page && query == "") {
            this.stopDomain = true;
          }
          this.dominioOptions = this.dominioOptions.concat(resp.data);        
        });
    },
    debounceDomains: _.debounce(function (query) {
      this.resetDomainSelect();
      if (query === "") {
        this.pageDomain = 1;
      }
      this.fetchDomains(this.pageDomain, query);
    }, 500),
    resetDomainSelect(){
      this.pageDomain = 1;
      this.stopDomain = false;
      this.dominioOptions = [];
    },
    pageStatus(status) {
      this.pagina.status = status;
    },
    validCss(event) {
      var css = this.dynamic_css
        .replace(/<\/?style\s*.*?>|<\/?style>/g, "")
        .replace(/<\/?script\s*.*?>|<\/?script>/g, "");
      var originalEvent = this.dynamic_css;
      if (css == originalEvent) {
        this.errorCss = false;
      } else {
        this.errorCss = true;
      }
    },
    validJs() {
      var js = this.dynamic_js
        .replace(/<\/?style\s*.*?>|<\/?style>/g, "")
        .replace(/<\/?script\s*.*?>|<\/?script>/g, "");
      var originalEvent = this.dynamic_js;
      if (js == originalEvent) {
        this.dynamic_js = this.dynamic_js.replace(
          /console\.log\((['"])(.+?)(['"]),([^)]+)\);/g,
          "console.log(`$2`,$4);"
        );
        this.dynamic_js = this.dynamic_js.replace(
          /console\.log\((['"])(.+?)(['"])(,[^)]*)?\);/g,
          "console.log(`$2`);$4"
        );
        this.errorJs = false;
      } else {
        this.errorJs = true;
      }
    },
    deletaViews() {
      Vue.swal({
        title: "Zerar estatísticas",
        text: `Deseja zerar estatísticas da página?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Zerar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          servicePage
            .destroy({ id: `reset/${this.editPage.id}` })
            .then(() => {
              this.loading = false;
              this.$grToast.toast("Página zerada com sucesso", {
                title: "Página",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$parent.getPages();
              this.$bvModal.hide("edicao-rapida");
            })
            .catch(() => {
              this.loading = false;
              this.$grToast.toast("Erro ao zerar página", {
                title: "Página",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    removeAccents(str) {
      // Converte para caracteres comuns
      str = str
        .toLowerCase()
        .replace(/[áàãâä]/gi, "a")
        .replace(/[éèêë]/gi, "e")
        .replace(/[íìîï]/gi, "i")
        .replace(/[óòõôö]/gi, "o")
        .replace(/[úùûü]/gi, "u")
        .replace(/[ç]/gi, "c")
        .replace(/[ñ]/gi, "n")
        .replace(/\s+/g, "-");
      // Remove caracteres especiais
      str = str.replace(/(?!-)[^a-z0-9]/gi, "");
      str = str.replace(/-+/gi, "-");
      str = str.replace(/  ?/gi, "");
      return str;
    },

    onSubmit() {
      if (this.errorCss) {
        this.$grToast.toast("Insira um código CSS sem a tag <style>", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.errorJs) {
        this.$grToast.toast("Insira um código JavaScript sem a tag <script>", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      this.loading = true;

      this.savePixelsPage();

      var link = this.removeAccents(this.pagina.link);
      var data = {
        id: "edit/" + this.editPage.id,
        campaign_id: this.$route.params.id,
        title: this.pagina.titulo,
        status: this.pagina.status.value,
        path_name: link,
        metas: {
          allowCopy: this.allowCopy ? "true" : "false",
          html_header_custom: this.html_header,
          html_foot_custom: this.html_body,

          time_redirect_switch: this.time_redirect ? "true" : "false",
          time_redirect: this.time_redirect ? this.redirect_time : "",

          page_date_switch: this.date_switch,
          redirect_page_date_inactive: this.data_redirect
            ? moment(this.dateRange1.startDate).format("YYYY-MM-DD HH:mm")
            : "",

          redirect_page:  this.redirect_url,

          page_domain: this.pagina.dominio ? this.pagina.dominio.id : 0,
          page_data: this.data_redirect ? "true" : "false",
          dynamic_js: this.dynamic_js,
          dynamic_css: this.dynamic_css,
        },
      };
      if (!this.pagina.titulo || this.pagina.titulo == "") {
        this.$grToast.toast("Preencha o título", {
          title: "Página",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      if (/  /g.test(this.pagina.titulo)) {
        this.$grToast.toast("Espaços inválidos no título", {
          title: "Página",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;

        return;
      }

      servicePage
        .update(data)
        .then(() => {
          this.loading = false;
          this.$grToast.toast("Página salva com sucesso", {
            title: "Página",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("edicao-rapida");
          this.$parent.getPages();
        })
        .catch(() => {
          this.loading = false;
          this.$grToast.toast("Erro ao salvar página", {
            title: "Página",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    savePixelsPage(id) {
      var pixels = [];

      for (let i = 0; i < this.pixel.length; i++) {
        const element = this.pixel[i];
        pixels.push({id: element.id, conversion_label: element.conversion_label});
      }

      var data = {
        id: "assoc",
        pixel_id: pixels,
        page_id: this.editPage.id,
      };

      servicePixel.createId(data);
    },
    deletePixels(e) {
      if (this.pixel.length === 0) {
        servicePixel.destroy({ id: `/events/${this.editPage.id}` });
      }
    },

    fetchPixelsPage() {
      servicePixel
        .read({ id: "/page/" + this.editPage.id })
        .then((response) => {
          this.pixel = response;
        });
    },

    debouncePixel: _.debounce(function (query) {
      this.searchPixel = query;
      this.pagePixel = 1;
      this.stop2 = false;
      this.fetchPixels(this.pagePixel, query);
    }, 500),
    fetchPixels(page = 1, query = "") {
      if (this.stop2 || this.loadingPixel) {
        return;
      }

      this.loadingPixel = true;

      var data = {
        page: `${page}&order_by=title&order=ASC&search=${query}`,
      };

      servicePixel
        .search(data)
        .then((resp) => {
          if (!resp.data.length) {
            this.stop2 = true;
            return;
          }

          this.pixelOptions = this.pixelOptions.concat(resp.data);

          this.pixelOptions = this.pixelOptions.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((err) => {
          this.pixelOptions = [];
        })
        .finally(() => {
          this.loadingPixel = false;
        });
    },
    getMeta(metas, chave) {

      if (!metas || !chave) {
        return;
      }

      var result = metas.find((x) => x.meta_key == chave);

      if (!result) {
        return "";
      }
      return result.meta_value;
    },
    fetchPageListService() {
      let data = {
        per_page: 1000,
      };

      PaginaListService.create(data).then((response) => {
        response.data.forEach((element) => {
          this.paginaOptions.push({
            id: element.id,
            name: element.title,
          });
        });
        this.paginaOptions.unshift({
          id: "",
          name: "Selecione uma página",
        });
      });
    },
    async openModal() {
      this.fetchPageListService();
      this.fetchPixelsPage();
      // this.fetchPixels();
      // this.campanha = this.getMeta(this.editPage.metas, "campanha");

      this.pagina.titulo = this.editPage.title;
      var status = this.editPage.status;
      if (status == "publish") {
        this.pagina.status = { value: "publish", text: "Publicada" };
      } else {
        this.pagina.status = { value: "draft", text: "Rascunho" };
      }
      this.pagina.link = this.editPage.path_name;
      setTimeout(() => {
        this.pagina.status = this.statusOptions.find(
          (item) => item.value == this.editPage.status
        );
        this.downloadImg()
      }, 100);

      setTimeout(() => {
        let id = this.getMeta(this.editPage.metas, "page_domain");
        let domain = this.dominioOptions.find((item) => item.id == id);
        
        if(domain){
          this.pagina.dominio = domain;
        }else{
          serviceDomain
            .read({id: id})
            .then((domain) => {
              this.pagina.dominio = domain;
            })
            .catch(() => {
              this.pagina.dominio = {};
            })
        }

      }, 100);
      this.html_header = this.getMeta(
        this.editPage.metas,
        "html_header_custom"
      );
      this.html_body = this.getMeta(this.editPage.metas, "html_foot_custom");
      this.time_redirect =
        this.getMeta(this.editPage.metas, "time_redirect_switch") === "true"
          ? true
          : false;
      this.redirect_time = this.getMeta(this.editPage.metas, "time_redirect");
      this.redirect_url = this.getMeta(this.editPage.metas, "redirect_page");
      this.dynamic_js = this.getMeta(this.editPage.metas, "dynamic_js");
      this.dynamic_css = this.getMeta(this.editPage.metas, "dynamic_css");
      this.allowCopy = this.getMeta(this.editPage.metas, "allowCopy")
      this.validCss(this.dynamic_css);
      this.validJs(this.dynamic_js);
      // this.url_block =
      //   "https://" +
      //   this.domain +
      //   "/" +
      //   this.editPage.post_name +
      //   "?tk=" +
      //   this.editPage.md5_id;

      this.data_redirect =
        this.getMeta(this.editPage.metas, "redirect_page_date_inactive") 
          ? true
          : false;

        var data = this.getMeta(
          this.editPage.metas,
          "redirect_page_date_inactive"
        );

        if (!data || data === "Invalid date") {
          this.dateRange1.startDate = moment();
          this.dateRange1.endDate = moment();
        } else {
          this.dateRange1.startDate = moment(data);
          this.dateRange1.endDate = moment(data);
        }
      
    },
  },
};
</script>

<style lang="scss" scoped>
.message-error {
  color: var(--red);
  font-size: 14px;
  font-weight: 600;
}
.decoration-none{
  text-decoration: none;
  color: inherit;
  cursor: inherit;
}
.content-feke-button{
  color: #fff;
  fill: #fff;
  display: flex;
  align-items: center;
  svg{
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
}
.feke-base-button{
  width: 100%;
  outline: none !important;
  font-weight: 600;
  height: 50px;
  padding: 0 30px !important;
  border-radius: 10px !important;
  font-size: 14px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  color: #fff;
  fill: #fff;
  background: var(--greenn);
}
.feke-base-button:hover{
  transform: scale(1.02);
}
.notValid {
  border-color: var(--red-dark) !important;
  color: var(--gray04) !important;
}
.grid-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 30px;
}
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}

.grid-templates {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  height: 55vh;
  overflow-y: auto;
  .item {
    width: 100%;
  }
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 30px;
}

.title-thumb {
  margin-top: 12px;
  font-weight: 600;
  text-align: center;
}

.actions {
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s linear;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  padding: 0px 16px;
  top: 0;
  left: 0;

  padding-top: 50%;
  button {
    width: 100% !important;
  }
}

.teste {
  position: relative;
}

.teste:hover .actions {
  opacity: 1 !important;
}

.wrapper {
  border: none !important;
  padding: 0 15px !important;
}

.page-body {
  padding: 0 40px !important;
}
</style>