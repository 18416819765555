import Rest from '@/services/Rest';

/**
 * @typedef {PixelService}
 */
export default class PixelService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'pixel-post'


}