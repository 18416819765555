<template>
  <div>
    <img src="../../assets/img/img_null.png" alt="imagem invisível" ref="invisible" v-show="false">
    <div v-show="campaignExists">
      <div
        style="
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          margin-right: auto;
          margin-left: auto;
        "
        class="container unic_list"
      >
        <BaseHeader :title="filterNameCampanha" :navigation="navigation">
        </BaseHeader>
        <b-tabs
          :class="{ 'mt-4': !isMobile }"
          nav-class="TabHeader no-border"
          active-nav-item-class="TabHeader--active"
          :no-nav-style="true"
        >
          <b-tab
            @click="setQuery('metricas')"
            :title="`Métricas`"
            :active="
              $route.query.tab === 'metricas' ||
              selectedTab === 'metricas' ||
              ($route.query.tab === undefined && selectedTab === 'metricas')
            "
          >
            <div class="d-flex justify-content-end">
              <div style="width: 250px">
                <multiselect
                  v-model="periodo"
                  placeholder="Selecione o período"
                  label="name"
                  track-by="value"
                  :options="periodos"
                  @select="createMetrics($event.value)"
                  :multiple="false"
                  :taggable="true"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  ><span slot="noResult">Nenhum motivo encontrado.</span>
                </multiselect>
              </div>
            </div>
            <div class="graphic-metrics" v-if="!isMobile">
              <div class="card-metrics" v-if="!loading">
                <div class="header-card">
                  <div class="text">
                    <p class="text">Acessos</p>
                  </div>
                  <div class="main-text">
                    <p class="text">{{ views }}</p>
                    <div
                      :class="{
                        'por-text': percentage_view > 0,
                        'por-text2': percentage_view < 0,
                      }"
                    >
                      <span> {{ percentage_view }}%</span>
                    </div>
                  </div>
                  <div class="footer">
                    <svg
                      v-if="percentage_view > 0 && percentage_view != 0"
                      width="18"
                      height="11"
                      viewBox="0 0 18 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 1L10.0909 7.90909L6.45455 4.27273L1 9.72727M17 1H12.6364M17 1V5.36364"
                        stroke="#4ea934"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else-if="percentage_view != 0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#d31408"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trending-down"
                    >
                      <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                      <polyline points="17 18 23 18 23 12"></polyline>
                    </svg>
                    <p class="text-footer">
                      {{ percentage_view }}% de acessos em
                      {{ periodo.value }} dias
                    </p>
                  </div>
                </div>
              </div>

              <div v-else>
                <b-skeleton width="380px" height="180px"></b-skeleton>
              </div>

              <div class="card-metrics" v-if="!loading">
                <div class="header-card">
                  <div class="text">
                    <p class="text">Leads</p>
                  </div>
                  <div class="main-text">
                    <p class="text">{{ lead }}</p>
                    <div
                      :class="{
                        'por-text': percentage_lead > 0,
                        'por-text2': percentage_lead < 0,
                      }"
                    >
                      <span> {{ percentage_lead }}% </span>
                    </div>
                  </div>
                  <div class="footer">
                    <svg
                      v-if="percentage_lead > 0 && percentage_lead != 0"
                      width="18"
                      height="11"
                      viewBox="0 0 18 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 1L10.0909 7.90909L6.45455 4.27273L1 9.72727M17 1H12.6364M17 1V5.36364"
                        stroke="#4ea934"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else-if="percentage_lead != 0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#d31408"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trending-down"
                    >
                      <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                      <polyline points="17 18 23 18 23 12"></polyline>
                    </svg>
                    <p class="text-footer">
                      {{ percentage_lead }}% de leads em
                      {{ periodo.value }} dias
                    </p>
                  </div>
                </div>
              </div>

              <div v-else>
                <b-skeleton width="380px" height="180px"></b-skeleton>
              </div>

              <!-- <div class="card-metrics" v-if="!loading">
                <div class="header-card">
                  <div class="text">
                    <p class="text">Cadastros</p>
                  </div>
                  <div class="main-text">
                    <p class="text">992.317</p>
                    <div :class="{'por-text': percentage_view > 0, 'por-text2': percentage_view < 0}">
                      <span> 104.64% </span>
                    </div>
                  </div>
                  <div class="footer">
                    <svg
                      width="18"
                      height="11"
                      viewBox="0 0 18 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 1L10.0909 7.90909L6.45455 4.27273L1 9.72727M17 1H12.6364M17 1V5.36364"
                        stroke="#4ea934"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p class="text-acecess">68% de alguma coisa</p>
                  </div>
                </div>
              </div> -->

              <!-- <div v-else>
                <b-skeleton width="280px" height="140px"></b-skeleton>
              </div> -->

              <div class="card-metrics" v-if="!loading">
                <div class="header-card">
                  <div class="text">
                    <p class="text">Vendas</p>
                  </div>
                  <div class="main-text">
                    <p class="text">{{ sales }}</p>
                    <div
                      :class="{
                        'por-text': percentage_sales > 0,
                        'por-text2': percentage_sales < 0,
                      }"
                    >
                      <span> {{ percentage_sales }}% </span>
                    </div>
                  </div>
                  <div id="product-select" class="footer-access">
                    <multiselect
                      v-model="selected_product_integration"
                      placeholder="Selecione o produto"
                      label="product_name"
                      track-by="id"
                      :options="products"
                      @select="
                        createMetrics(
                          periodo.value,
                          $event.product_greenn_id,
                          $event
                        )
                      "
                      :multiple="false"
                      :taggable="true"
                      class=""
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      ><span slot="noResult">Nenhum motivo encontrado.</span>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div v-else>
                <b-skeleton width="380px" height="180px"></b-skeleton>
              </div>
            </div>

            <div class="graphic-metrics-mobile" v-if="isMobile">
              <div class="card-metrics">
                <div class="header-card">
                  <div class="text">
                    <p class="text">Acessos</p>
                  </div>
                  <div class="main-text">
                    <p class="text">{{ views }}</p>
                    <div
                      :class="{
                        'por-text': percentage_view > 0,
                        'por-text2': percentage_view < 0,
                      }"
                    >
                      <span> {{ percentage_view }}% </span>
                    </div>
                  </div>
                  <div class="footer">
                    <svg
                      v-if="percentage_view > 0 && percentage_view != 0"
                      width="18"
                      height="11"
                      viewBox="0 0 18 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 1L10.0909 7.90909L6.45455 4.27273L1 9.72727M17 1H12.6364M17 1V5.36364"
                        stroke="#4ea934"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else-if="percentage_view != 0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#d31408"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trending-down"
                    >
                      <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                      <polyline points="17 18 23 18 23 12"></polyline>
                    </svg>

                    <p class="text-footer">
                      {{ percentage_view }}% de acessos em
                      {{ periodo.value }} dias
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-metrics">
                <div class="header-card">
                  <div class="text">
                    <p class="text">Leads</p>
                  </div>
                  <div class="main-text">
                    <p class="text">{{ lead }}</p>
                    <div
                      :class="{
                        'por-text': percentage_lead > 0,
                        'por-text2': percentage_lead < 0,
                      }"
                    >
                      <span> {{ percentage_lead }}% </span>
                    </div>
                  </div>
                  <div class="footer">
                    <svg
                      v-if="percentage_lead > 0"
                      width="18"
                      height="11"
                      viewBox="0 0 18 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 1L10.0909 7.90909L6.45455 4.27273L1 9.72727M17 1H12.6364M17 1V5.36364"
                        stroke="#4ea934"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#d31408"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trending-down"
                    >
                      <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                      <polyline points="17 18 23 18 23 12"></polyline>
                    </svg>
                    <p class="text-footer">
                      {{ percentage_lead }}% de leads em
                      {{ periodo.value }} dias
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="card-metrics">
                <div class="header-card">
                  <div class="text">
                    <p class="text">Cadastros</p>
                  </div>
                  <div class="main-text">
                    <p class="text">992.317</p>
                    <div :class="{'por-text': percentage_view > 0, 'por-text2': percentage_view < 0}">
                      <span> 104.64% </span>
                    </div>
                  </div>
                  <div class="footer">
                    <svg
                      width="18"
                      height="11"
                      viewBox="0 0 18 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 1L10.0909 7.90909L6.45455 4.27273L1 9.72727M17 1H12.6364M17 1V5.36364"
                        stroke="#4ea934"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p class="text-acecess">68% de alguma coisa</p>
                  </div>
                </div>
              </div> -->
              <div class="card-metrics">
                <div class="header-card">
                  <div class="text">
                    <p class="text">Vendas</p>
                  </div>
                  <div class="main-text">
                    <p class="text">{{ sales }}</p>
                    <div
                      :class="{
                        'por-text': percentage_view > 0,
                        'por-text2': percentage_view < 0,
                      }"
                    >
                      <span> {{ percentage_sales }}% </span>
                    </div>
                  </div>
                  <div id="product-select" class="footer-access">
                    <multiselect
                      v-model="selected_product_integration"
                      placeholder="Selecione o produto"
                      label="product_name"
                      track-by="id"
                      :options="products"
                      @select="
                        createMetrics(
                          periodo.value,
                          $event.product_greenn_id,
                          $event
                        )
                      "
                      :multiple="false"
                      :taggable="true"
                      class=""
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      ><span slot="noResult">Nenhum motivo encontrado.</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>

            <div class="graph" data-anima="top">
              <div>
                <Chart3
                  :event="this.periodo.value"
                  :product="selected_product_integration"
                />
                <!-- <div class="container-loading w-100" v-else>
            <b-spinner label="Loading..."></b-spinner>
          </div> -->
              </div>
            </div>
          </b-tab>
          <b-tab
            @click="setQuery('paginas')"
            :active="
              $route.query.tab === 'paginas' || selectedTab === 'paginas'
            "
            v-if="$store.getters.user.user.level !== 'seller'"
            :title="`Páginas`"
          >
            <b-container fluid class="mt-1 wrapper container-user">
              <div class="box-total">
                <div class="total-vendas info">
                  <b-form-checkbox
                    v-model="status"
                    @change="changeStatus"
                    name="is_main"
                    size="lg"
                    switch
                  >
                    <p class="info-checkbox" style="width: 0px">
                      {{ status ? " Ativas " : "Inativas" }}
                    </p>
                  </b-form-checkbox>
                </div>
                <div>
                  <p class="total-vendas">
                    {{ isMobile ? "Páginas:" : "Total de páginas:" }}
                    <span>{{ total_pages }}</span>
                  </p>
                </div>
                <div></div>
              </div>
              <div class="header-table">
                <div class="inputSearch" style="position: relative">
                  <img
                    src="@/assets/img/icons/search.svg"
                    class="Pointer search"
                  />
                  <input
                    type="text"
                    placeholder="Pesquisar página"
                    v-model="search_pages"
                    @input="debounce_pages"
                    class="input-busca"
                  />
                </div>
                <div @click="limitPagesMessage()">
                  <BaseButton
                    variant="primary"
                    @click="openModal('Modal-New-Page')"
                    v-if="!isMobile"
                    style="margin-left: 10px"
                    :disabled="limitPages"
                  >
                    Criar página
                  </BaseButton>
                </div>
              </div>

              <!-- LISTAGEM DE PÁGINAS-->
              <div v-if="!loading && all_pages && all_pages.length > 0">
                <!-- HEADER -->
                <TransitionGroup
                  name="list"
                  tag="div"
                  class="paginas-conteudo-container"
                >
                  <div
                    class="paginas-conteudo"
                    v-for="(page, index) in all_pages"
                    :key="index"
                  >
                    <div @click="openPage(page)" :ref="`${'pageId_'+page.id}`" style="cursor: pointer" data-path-redirect="false">
                      <img
                        v-if="page.metas && getMeta(page.metas, 'page_cover')"
                        class="img-conteudo"
                        :src="getMeta(page.metas, 'page_cover')"
                        alt="imagem-vazia"
                        @error="setAltImg"
                      />
                      <img
                        v-else
                        class="img-conteudo"
                        src="@/assets/img/icons/img_null.svg"
                        alt="imagem-vazia"
                      />
                    </div>
                    <div class="paginas-descricao">
                      <div class="display-flex2">
                        <h1 class="paginas-descricao-titulo">
                          {{ page.title }}
                        </h1>
                        <div
                          id="pagina-body"
                          v-if="
                            !isMobile &&
                            level !== 'gerent_seller' &&
                            level !== 'seller'
                          "
                        >
                          <b-dropdown
                            id="dropPage"
                            right
                            style="margin-top: 12px"
                            v-if="status"
                          >
                            <template #button-content>
                              <img
                                src="@/assets/img/icons/pontos.svg"
                                alt="dots"
                              />
                            </template>
                            <b-dropdown-item @click="openModalEdicao(page)"
                              >Edição Rápida</b-dropdown-item
                            >
                            <b-dropdown-item
                              @click="redirectEditorEdit(page.id)"
                            >
                              Editar</b-dropdown-item
                            >
                            <b-dropdown-item @click="clone(page.id)"
                              >Clonar Página</b-dropdown-item
                            >
                            <b-dropdown-item @click="inactivePage(page)"
                              >Desativar</b-dropdown-item
                            >
                          </b-dropdown>
                          <b-dropdown
                            id="dropPage"
                            right
                            style="margin-top: 12px"
                            v-else
                          >
                            <template #button-content>
                              <img
                                src="@/assets/img/icons/pontos.svg"
                                alt="dots"
                              />
                            </template>
                            <b-dropdown-item @click="restaurarPagina(page)"
                              >Ativar</b-dropdown-item
                            >
                            <b-dropdown-item @click="deletePage(page)"
                              >Deletar</b-dropdown-item
                            >
                          </b-dropdown>
                        </div>
                      </div>
                      <div class="display-flex">
                        <img
                          class="img-icon"
                          src="@/assets/img/icons/hash.svg"
                          alt="icon"
                        />
                        <div>{{ page.id }}</div>
                      </div>
                      <div>
                        <div class="display-flex">
                          <img
                            class="img-icon"
                            src="@/assets/img/icons/raio.svg"
                            alt="icon"
                          />
                          <div>{{ page.visitas }} Visitas</div>
                        </div>
                        <div class="display-flex">
                          <img
                            class="img-icon"
                            src="@/assets/img/icons/estrela.svg"
                            alt="icon"
                          />
                          <div>{{ page.convercao }} Conversões</div>
                        </div>
                        <div
                          style="
                            text-align: end;
                            position: relative;
                            top: -20px;
                          "
                          v-if="page.visitas && page.convercao"
                        >
                          {{ percentage(page.convercao, page.visitas) }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </TransitionGroup>
                <b-row>
                  <b-col>
                    <Paginate
                      v-if="all_pages.length > 0"
                      :totalPages="pagination_pages.totalPages"
                      :activePage="pagination_pages.currentPage"
                      @to-page="toPage_pages"
                      @per-page="perPage_pages"
                      :disabled="true"
                    />
                  </b-col>
                </b-row>
              </div>

              <div v-if="loading" class="d-flex" style="gap: 20px">
                <div v-for="(n, index) in 3" :key="index">
                  <b-skeleton width="360px" height="200px"></b-skeleton>
                  <b-skeleton
                    width="280px"
                    height="30px"
                    class="mt-3"
                  ></b-skeleton>
                  <b-skeleton
                    width="150px"
                    height="20px"
                    class="mt-3"
                  ></b-skeleton>
                  <b-skeleton
                    width="180px"
                    height="20px"
                    class="mt-3"
                  ></b-skeleton>
                  <b-skeleton
                    width="220px"
                    height="20px"
                    class="mt-3"
                  ></b-skeleton>
                </div>
              </div>
              <!-- PÁGINA NÃO ENCONTRADA-->
              <b-row
                v-if="
                  !loading &&
                  all_pages &&
                  all_pages.length === 0 &&
                  search_pages.length > 0
                "
                class="Table-body justify-content-center"
              >
                <p class="nao-encontrado">Nenhuma página foi encontrada</p>
              </b-row>

              <!-- SE NÃO EXISTIR PÁGINA -->
              <b-row
                v-if="
                  !loading &&
                  all_pages.length === 0 &&
                  search_pages.length === 0
                "
                class="Table-body justify-content-center"
              >
                <p class="nao-encontrado">Nenhuma página criada</p>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab
            @click="setQuery('formularios')"
            :title="`Formulários`"
            :active="
              $route.query.tab === 'formularios' ||
              selectedTab === 'formularios'
            "
          >
            <b-container fluid class="mt-1 wrapper container-user">
              <div class="box-total">
                <div class="total-vendas info">
                  <b-form-checkbox
                    v-model="status_form"
                    @change="changeStatusForm"
                    name="is_main"
                    size="lg"
                    switch
                  >
                    <p class="info-checkbox" style="width: 0px">
                      {{ status_form ? " Ativos " : "Inativos" }}
                    </p>
                  </b-form-checkbox>
                </div>
                <div>
                  <p class="total-vendas">
                    {{ isMobile ? "Formulários:" : "Total de formulários:" }}
                    <span>{{ total_forms }}</span>
                  </p>
                </div>
                <div></div>
              </div>
              <div class="header-table">
                <div class="inputSearch" style="position: relative">
                  <img
                    src="@/assets/img/icons/search.svg"
                    class="Pointer search"
                  />
                  <input
                    type="text"
                    placeholder="Pesquisar formulário"
                    v-model="search_forms"
                    @input="debounce_forms"
                    class="input-busca"
                  />
                </div>
                <BaseButton
                  variant="primary"
                  style="margin-left: 10px"
                  v-if="!isMobile"
                  @click="
                    $router.push({
                      name: 'CriarFormulario',
                      params: { campaign_id: $route.params.id },
                    })
                  "
                >
                  Criar formulário
                </BaseButton>
              </div>

              <!-- LISTAGEM DE FORMULARIOS-->
              <div>
                <div class="forms-cards-wrapper">
                  <div v-for="(form, index) in all_forms" :key="index" v-show="!loading">
                    <div class="form-preview">
                      <div v-for="inputs in form.fields" v-html="inputs.form_field"></div>
                    </div>
                    <div class="form-tags">
                      <div 
                        v-if="formsTagsConfigs[index].hasDistribuition"
                        v-b-tooltip.hover
                        title="Distribuição de leads para usuários específicos"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path d="M230.93,220a8,8,0,0,1-6.93,4H32a8,8,0,0,1-6.92-12c15.23-26.33,38.7-45.21,66.09-54.16a72,72,0,1,1,73.66,0c27.39,8.95,50.86,27.83,66.09,54.16A8,8,0,0,1,230.93,220Z"></path></svg>
                      </div>
                      <div 
                        v-if="formsTagsConfigs[index].hasCRMAutomation"
                        v-b-tooltip.hover
                        title="Conectado a um Board do CRM"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path d="M200,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24ZM96,48h64a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16Zm84.81,150.4a8,8,0,0,1-11.21-1.6,52,52,0,0,0-83.2,0,8,8,0,1,1-12.8-9.6A67.88,67.88,0,0,1,101,165.51a40,40,0,1,1,53.94,0A67.88,67.88,0,0,1,182.4,187.2,8,8,0,0,1,180.81,198.4ZM152,136a24,24,0,1,1-24-24A24,24,0,0,1,152,136Z"></path></svg>
                      </div>
                      <div 
                        v-if="formsTagsConfigs[index].hasTagAutomation"
                        v-b-tooltip.hover
                        title="Adiciona/remove tags dos leads"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path d="M243.31,136,144,36.69A15.86,15.86,0,0,0,132.69,32H40a8,8,0,0,0-8,8v92.69A15.86,15.86,0,0,0,36.69,144L136,243.31a16,16,0,0,0,22.63,0l84.68-84.68a16,16,0,0,0,0-22.63ZM84,96A12,12,0,1,1,96,84,12,12,0,0,1,84,96Z"></path></svg>
                      </div>
                      <div 
                        v-if="formsTagsConfigs[index].hasThankYouPage"
                        v-b-tooltip.hover
                        title="Possui página de agradecimento"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path d="M136.37,187.53a12,12,0,0,1,0,17l-5.94,5.94a60,60,0,0,1-84.88-84.88l24.12-24.11A60,60,0,0,1,152,99,12,12,0,1,1,136,117a36,36,0,0,0-49.37,1.47L62.53,142.55a36,36,0,0,0,50.92,50.92l5.94-5.94A12,12,0,0,1,136.37,187.53Zm74.08-142a60.09,60.09,0,0,0-84.88,0l-5.94,5.94a12,12,0,0,0,17,17l5.94-5.94a36,36,0,0,1,50.92,50.92l-24.11,24.12A36,36,0,0,1,120,139,12,12,0,1,0,104,157a60,60,0,0,0,82.3-2.43l24.12-24.11A60.09,60.09,0,0,0,210.45,45.55Z"></path></svg>
                      </div> 
                      <div 
                        v-if="formsTagsConfigs[index].hasLeadScore"
                        v-b-tooltip.hover
                        title="Adiciona temperatura ao lead"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path d="M160,146.08V40a32,32,0,0,0-64,0V146.08a56,56,0,1,0,64,0ZM128,24a16,16,0,0,1,16,16V80H112V40A16,16,0,0,1,128,24Z"></path></svg>
                      </div> 
                    </div> 
                    <div class="form-edit" @click=" $router.push({name: 'EditarFormulario', params: { campaign_id: $route.params.id, id: form.id}, }) ">
                      <svg viewBox="0 0 24 24" width="48" height="48" style="stroke: var(--greenn);" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                    </div>
                    <span>#{{ form.id }}</span>
                    <div>
                      <div>
                        <span class="form-name">{{ form.title }}</span>
                        <span class="form-date">{{ form.created_at | moment("DD/MM/YYYY HH:mm") }}</span>
                      </div>
                      <div>
                        <svg v-if="status_form" @click="inactiveForm(form.id)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: var(--red);" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>
                        <svg v-else @click="restaurarForm(form.id)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: var(--greenn);" viewBox="0 0 256 256"><path d="M136,80v43.47l36.12,21.67a8,8,0,0,1-8.24,13.72l-40-24A8,8,0,0,1,120,128V80a8,8,0,0,1,16,0Zm-8-48A95.44,95.44,0,0,0,60.08,60.15C52.81,67.51,46.35,74.59,40,82V64a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16H49c7.15-8.42,14.27-16.35,22.39-24.57a80,80,0,1,1,1.66,114.75,8,8,0,1,0-11,11.64A96,96,0,1,0,128,32Z"></path></svg>
                        <svg @click="openModal2('formHtml', form)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256"><path d="M69.12,94.15,28.5,128l40.62,33.85a8,8,0,1,1-10.24,12.29l-48-40a8,8,0,0,1,0-12.29l48-40a8,8,0,0,1,10.24,12.3Zm176,27.7-48-40a8,8,0,1,0-10.24,12.3L227.5,128l-40.62,33.85a8,8,0,1,0,10.24,12.29l48-40a8,8,0,0,0,0-12.29ZM162.73,32.48a8,8,0,0,0-10.25,4.79l-64,176a8,8,0,0,0,4.79,10.26A8.14,8.14,0,0,0,96,224a8,8,0,0,0,7.52-5.27l64-176A8,8,0,0,0,162.73,32.48Z"></path></svg>
                      </div>
                    </div>
                  </div>
                  <div v-for="item in 6" :key="item * 100" v-show="loading">
                    <div class="form-preview">
                      <div v-for="n in 3" class="w-100">
                        <div class="shine" style="height: 16px; width: 30%; margin-bottom: 10px;"></div>
                        <div class="shine" style="height: 45px; width: 100%; margin-bottom: 15px;"></div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="shine" style="height: 16px; width: 70%; margin-bottom: 10px;"></div>
                        <div class="shine" style="height: 12px; width: 30%;"></div>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <b-row>
                <b-col>
                  <Paginate
                    v-if="all_forms.length > 9"
                    :totalPages="pagination_forms.totalPages"
                    :activePage="pagination_forms.currentPage"
                    @to-page="toPage_forms"
                    @per-page="perPage_forms"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
              <!-- FORMULARIO NÃO ENCONTRADO-->
              <b-row
                v-if="
                  !loading &&
                  all_forms &&
                  all_forms.length === 0 &&
                  search_forms.length > 0
                "
                class="Table-body justify-content-center"
              >
                <p class="nao-encontrado">Nenhum formulário foi encontrado</p>
              </b-row>

              <!-- SE NÃO EXISTIR FORMULARIO -->
              <b-row
                v-if="
                  !loading &&
                  all_forms.length === 0 &&
                  search_forms.length === 0
                "
                class="Table-body justify-content-center"
              >
                <p class="nao-encontrado">Nenhum formulário criado</p>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab
            @click="setQuery('automacoes')"
            :title="`Funil de e-mails`"
            :active="
              $route.query.tab === 'automacoes' || selectedTab === 'automacoes'
            "
          >
            <b-container fluid class="mt-1 wrapper container-user">
              <div class="box-total">
                <div class="total-vendas info"></div>
                <div>
                  <p class="total-vendas">
                    {{ isMobile ? "Funis:" : "Total de funis:" }}
                    <span>{{ total_funnels }}</span>
                  </p>
                </div>
                <div></div>
              </div>
              <div class="header-table">
                <div class="inputSearch" style="position: relative">
                  <img
                    src="@/assets/img/icons/search.svg"
                    class="Pointer search"
                  />
                  <input
                    type="text"
                    placeholder="Pesquisar funis"
                    v-model="search_funnel"
                    @input="debounce_funnel"
                    class="input-busca"
                  />
                </div>
                <BaseButton
                  variant="primary"
                  v-if="!isMobile"
                  @click="openModal('Modal-Create-Funil')"
                  style="margin-left: 10px"
                >
                  Criar funil de e-mails
                </BaseButton>
              </div>
              <!-- LISTAGEM DE Automações-->
              <div v-if="!loading && all_funnel && all_funnel.length > 0">
                <!-- HEADER -->
                <b-row class="Table-header mb-1" v-if="!isMobile">
                  <b-col
                    cols="2"
                    class="d-none d-md-block"
                    style="padding-left: 23px"
                  >
                    ID
                  </b-col>
                  <b-col
                    cols="3"
                    class="d-none d-md-block"
                    style="padding-left: 20px"
                  >
                    Título
                  </b-col>
                  <b-col cols="3" class="d-none d-md-block"> Criado em </b-col>
                  <b-col
                    cols="3"
                    class="d-none d-md-block"
                    style="padding-left: 10px"
                    >Edição</b-col
                  >
                  <b-col
                    cols="1"
                    class="d-none d-md-block"
                    style="padding-left: 8px"
                    >Ações</b-col
                  >
                </b-row>
                <TransitionGroup name="list" tag="div">
                  <div
                    v-for="(funnel, index) in all_funnel"
                    :key="index"
                    class="Table-body-container"
                  >
                    <!-- BODY -->
                    <template v-if="!isMobile">
                      <b-row class="Table-body" id="border-bottom">
                        <!-- id -->
                        <b-col class="id" cols="2">
                          {{ funnel.id }}
                        </b-col>
                        <!--título -->
                        <b-col cols="3" style="word-break: break-all">
                          {{ funnel.title }}
                        </b-col>
                        <b-col cols="3">
                          {{ funnel.created_at | moment("DD/MM/YYYY HH:mm") }}
                        </b-col>
                        <b-col cols="3" class="d-flex">
                          <BaseButton
                            @click="
                              $router.push({
                                name: 'CampanhasEtapas',
                                params: {
                                  campaign_id: $route.params.id,
                                  funnel_id: funnel.id,
                                },
                              })
                            "
                            style="padding-left: 0 !important"
                            variant="text-info"
                          >
                            Visualizar
                          </BaseButton>
                        </b-col>
                        <b-col cols="1" class="d-flex">
                          <b-dropdown id="dropForm" right>
                            <template #button-content>
                              <img
                                src="@/assets/img/icons/dots.svg"
                                alt="dots"
                              />
                            </template>
                            <b-dropdown-item
                              @click="openModal('Modal-Edit-Funil', funnel)"
                              >Editar</b-dropdown-item
                            >
                            <b-dropdown-item @click="deleteFunnel(funnel.id)"
                              >Deletar</b-dropdown-item
                            >
                          </b-dropdown>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </TransitionGroup>

                <!-- LISTAGEM DE Automações MOBILE-->
                <div v-if="isMobile" class="mt-4">
                  <b-row
                    class="Table-header mb-1"
                    style="
                      padding: 15px 0px !important;
                      gap: 0 !important;
                      justify-content: space-between;
                    "
                    v-if="isMobile"
                  >
                    <b-col
                      style="text-align: start; padding: 0 !important"
                      cols="1"
                    >
                      ID
                    </b-col>
                    <b-col
                      cols="4"
                      style="padding: 0 !important; text-align: start"
                    >
                      Título
                    </b-col>
                    <b-col
                      cols="4"
                      style="text-align: end; padding: 0 !important"
                    >
                      Criado em
                    </b-col>
                  </b-row>

                  <template>
                    <div
                      v-for="(funnel, index) in all_funnel"
                      :key="index"
                      class="Table-body-container"
                    >
                      <!-- BODY -->
                      <b-row
                        class="Table-body"
                        style="
                          padding: 0 2px !important;
                          gap: 0 !important;
                          justify-content: space-between;
                        "
                        id="border-bottom"
                      >
                        <!-- id -->
                        <b-col
                          class="id"
                          cols="1"
                          style="padding: 0 !important; font-size: 12.5px"
                        >
                          {{ funnel.id }}
                        </b-col>
                        <!--título -->
                        <b-col
                          cols="5"
                          style="
                            word-break: break-all;
                            padding: 0 !important;
                            font-size: 12.5px;
                          "
                        >
                          {{ funnel.title }}
                        </b-col>
                        <b-col
                          cols="3"
                          style="
                            text-align: end;
                            padding: 0px !important;
                            font-size: 12.5px;
                          "
                        >
                          {{ funnel.created_at | moment("DD/MM/YYYY") }}
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </div>
                <b-row>
                  <b-col>
                    <Paginate
                      v-if="all_funnel.length > 0"
                      :totalPages="pagination_funnel.totalPages"
                      :activePage="pagination_funnel.currentPage"
                      @to-page="toPage_funnel"
                      @per-page="perPage_funnel"
                      :disabled="false"
                    />
                  </b-col>
                </b-row>
              </div>
              <!-- FORMULARIO NÃO ENCONTRADO-->
              <b-row
                v-if="
                  !loading &&
                  all_funnel &&
                  all_funnel.length === 0 &&
                  search_funnel.length > 0
                "
                class="Table-body justify-content-center"
              >
                <p class="nao-encontrado">Nenhuma automação foi encontrada</p>
              </b-row>
              <!-- SE EXISTIR LOADING-->
              <div class="d-flex justify-content-center" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
              <!-- SE NÃO EXISTIR FORMULARIO -->
              <b-row
                v-if="
                  !loading &&
                  all_funnel.length === 0 &&
                  search_funnel.length === 0
                "
                class="Table-body justify-content-center"
              >
                <p class="nao-encontrado">Nenhuma automação criada</p>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab
            v-if="$store.getters.user.user.level !== 'blogger' && false"
            style="position: relative;"
            @click="setQuery('integracoes')"
            :title="`Integrações`"
            :active="
              $route.query.tab === 'integracoes' ||
              selectedTab === 'integracoes'
            "
          >
              <!-- HIDDEN-FEATURE -->
            <div class="in-progress">
              <svg fill="#4ea934" width="200px" height="200px" viewBox="0 -8 72 72"><polygon points="52.7 39.8 19.61 39.8 19.61 33.91 11.91 33.91 11.91 51.6 19.61 51.6 19.61 45.6 52.7 45.6 52.7 52 60.39 52 60.39 34.3 52.7 34.3 52.7 39.8"/><polygon points="33.3 12.01 23.21 12.01 11.13 31.21 21.22 31.21 33.3 12.01"/><polygon points="35.22 31.21 47.3 12.01 38.18 12.01 26.1 31.21 35.22 31.21"/><path d="M66.51,12,54.43,31.21H66.16c1.86,0,2.23-1,2.23-2.5V12Z"/><polygon points="49.55 31.21 61.63 12.01 52.18 12.01 40.1 31.21 49.55 31.21"/><path d="M5.64,12s-2-.3-2,2.17v17H6.25L18.33,12Z"/></svg>
              <h3 class="title">Atualizações em Progresso<br><span style="font-size: 14px; color: inherit; margin-top: 8px; opacity: 0.7;">Estamos dedicados a melhorar sua experiência.<br>Em breve, traremos mais novidades!</span></h3>
            </div>
            <b-container @mouseover="hide = true" v-if="!hide" fluid class="mt-1 wrapper container-user">
              <div class="box-total">
                <div class="total-vendas info"></div>
                <div>
                  <p class="total-vendas">
                    {{ isMobile ? "Integrações" : "Total de integrações:" }}
                    <span>{{ total_integrations }}</span>
                  </p>
                </div>
                <div></div>
              </div>
              <div class="header-table">
                <div class="inputSearch" style="position: relative">
                  <img
                    src="@/assets/img/icons/search.svg"
                    class="Pointer search"
                  />
                  <input
                    type="text"
                    placeholder="Pesquisar integração"
                    v-model="search_integration"
                    @input="debounce_integration_product"
                    class="input-busca"
                  />
                </div>
                <BaseButton
                  variant="primary"
                  v-if="!isMobile"
                  @click="openModal3('Modal-integration')"
                  style="margin-left: 10px"
                >
                  Criar integrações
                </BaseButton>
              </div>
              <!-- LISTAGEM DE INTEGRACOES-->
              <div
                v-if="
                  !loading &&
                  all_products_integrations &&
                  all_products_integrations.length > 0
                "
              >
                <!-- HEADER -->
                <b-row class="Table-header mb-1" v-if="!isMobile">
                  <b-col
                    cols="2"
                    class="d-none d-md-block"
                    style="padding-left: 23px"
                  >
                    ID
                  </b-col>
                  <b-col
                    cols="3"
                    class="d-none d-md-block"
                    style="padding-left: 20px"
                  >
                    Nome da Integração
                  </b-col>
                  <!-- <b-col cols="2" class="d-none d-md-block"> Criado em </b-col> -->
                  <b-col
                    cols="3"
                    class="d-none d-md-block"
                    style="padding-left: 16px"
                    >Produto</b-col
                  >
                  <b-col
                    cols="2"
                    class="d-none d-md-block"
                    style="padding-left: 15px"
                    >Eventos</b-col
                  >
                  <b-col
                    cols="2"
                    class="d-none d-md-block"
                    style="padding-left: 55px"
                    >Ações</b-col
                  >
                </b-row>

                <!-- <b-row>
                  <b-col>
                    <Paginate v-if="all_products_integrations.length > 0" :totalPages="pagination_integration.totalPages"
                      :activePage="pagination_integration.currentPage" @to-page="toPage_integration"
                      @per-page="perPage_integration" :disabled="false" />
                  </b-col>
                </b-row> -->
              </div>

              <!-- LISTAGEM DE INTEGRACOES MOBILE-->
              <div
                class="mt-4"
                v-if="
                  !loading &&
                  isMobile &&
                  all_products_integrations &&
                  all_products_integrations.length > 0
                "
              >
                <b-row
                  class="Table-header mb-1"
                  style="
                    padding: 15px 0px !important;
                    gap: 0 !important;
                    justify-content: space-between;
                  "
                  v-if="isMobile"
                >
                  <b-col
                    style="text-align: start; padding: 0 !important"
                    cols="1"
                  >
                    ID
                  </b-col>
                  <b-col
                    cols="5"
                    style="padding: 0 !important; text-align: start"
                  >
                    Nome da Integração
                  </b-col>
                  <b-col
                    cols="4"
                    style="text-align: end; padding: 0 !important"
                  >
                    Produto
                  </b-col>
                </b-row>
                <template>
                  <div
                    class="Table-body-container"
                    v-for="(product_i, index) in all_products_integrations"
                    :key="index"
                  >
                    <!-- BODY -->
                    <b-row
                      class="Table-body"
                      style="
                        padding: 0 2px !important;
                        gap: 0 !important;
                        justify-content: space-between;
                      "
                      id="border-bottom"
                    >
                      <b-col
                        class="id"
                        cols="1"
                        style="padding: 0 !important; font-size: 12.5px"
                      >
                        <!-- {{ product_i.id }} -->
                        1
                      </b-col>
                      <b-col
                        cols="5"
                        style="
                          word-break: break-all;
                          padding: 0 !important;
                          font-size: 12.5px;
                        "
                      >
                        <!-- {{ funnel.title }} -->
                        TESTE
                      </b-col>

                      <b-col
                        cols="4"
                        style="
                          text-align: end;
                          padding: 0px !important;
                          font-size: 12.5px;
                        "
                      >
                        ABC123
                        {{ product_i.product_name }}
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </div>

              <div v-else>
                <div
                  class="Table-body-container"
                  v-for="(product_i, index) in all_products_integrations"
                  :key="index"
                >
                  <div class="Table-body-container">
                    <!-- BODY -->
                    <template v-if="!isMobile">
                      <b-row class="Table-body" id="border-bottom">
                        <!-- id -->
                        <b-col class="id" cols="2">
                          {{ product_i.id }}
                        </b-col>
                        <!--título -->
                        <b-col cols="3" style="word-break: break-all">
                          {{ product_i.title }}
                        </b-col>
                        <!--produto -->
                        <b-col cols="3" style="word-break: break-all">
                          {{ product_i.product_name }}
                        </b-col>
                        <!--eventos -->
                        <b-col cols="2" style="word-break: break-all">
                          <span>{{ parseTitle(product_i.actions) }}</span>
                        </b-col>
                        <!--acoes -->

                        <b-col cols="2" style="word-break: break-all">
                          <BaseButton
                            @click="deleteIntegration(product_i.id)"
                            style=""
                            variant="text-danger"
                          >
                            Deletar
                          </BaseButton>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </div>
              </div>

              <!-- INTEGRAÇÃO NÃO ENCONTRADA-->
              <b-row
                v-if="
                  !loading &&
                  all_products_integrations &&
                  all_products_integrations.length === 0 &&
                  search_integration.length > 0
                "
                class="Table-body justify-content-center"
              >
                <p class="nao-encontrado">Nenhuma integração foi encontrada</p>
              </b-row>
              <!-- SE EXISTIR LOADING-->
              <div class="d-flex justify-content-center" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
              <!-- SE NÃO EXISTIR INTEGRAÇÃO -->
              <b-row
                v-if="
                  !loading &&
                  all_products_integrations &&
                  all_products_integrations.length === 0 &&
                  search_integration.length === 0
                "
                class="Table-body justify-content-center"
              >
                <p class="nao-encontrado">Nenhuma integração criada</p>
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
        <ModalEditFunil :selectedFunil="selectedFunil" :all_forms="all_forms" />
        <ModalCreateFunil :all_forms="all_forms" />
        <ModalAddIntegration @openModal3="openModal3" />
        <ModalAddPIntegration />
        <htmlModal :form="selectedHtml" />
        <EdicaoRapida @edicao-rapida="getPages()" :editPage="editPage" />
        <ModalNewPage @newPage="redirectEditorEdit"></ModalNewPage>
      </div>
    </div>
    <div v-if="!campaignExists" class="container">
      <div>
        <div class="containerCampaignNoExisting">
          <BaseHeader title="..."> </BaseHeader>
          <p>Edite aqui todas as particularidades do seu funil</p>
          <div class="viewAllCampaign">
            <div>
              <h4 class="text-dark">
                <span style="margin-left: -30px; position: absolute">💡</span
                >Ops, funil não encontrado
              </h4>
              <p>Infelizmente este funil não foi localizado no seu painel.</p>
              <BaseButton
                @click="$router.push('/dynamicRoute/funis/lista')"
                variant="black"
                class="mt-2"
                >Visualizar funis
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/main.js";

import BaseHeader from "@/components/BaseHeader";
//
import _ from "lodash";
import Vue from "vue";
import Paginate from "@/components/Paginate";
import moment from "moment-timezone";
import Chart3 from "@/components/Campanhas/Chart3.vue";
import DateRangePicker from "vue2-daterange-picker";
import Multiselect from "vue-multiselect";

import ProductIntegrationService from "@/services/resources/ProductIntegrationService";
const serviceProductIntegration = ProductIntegrationService.build();
//
import CampaignService from "@/services/resources/CampaignService";
const serviceCampaign = CampaignService.build();
//
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();

import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();
// 
import PageCountService from "@/services/resources/PageCountService";
const servicePageCount = PageCountService.build();
//
import PageListService2 from "@/services/resources/PageListService2";
const PaginaListService2 = PageListService2.build();
//
import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();
//
import FunnelService from "@/services/resources/FunnelService";
const serviceFunnel = FunnelService.build();

import ConfigProdutosService from "@/services/resources/ConfigProdutosService";
const serviceProduto = ConfigProdutosService.build();
//
import ModalEditFunil from "./Funil/ModalEditFunil.vue";
import ModalNewPage from "./ModalNewPage.vue";
import ModalCreateFunil from "./Funil/ModalCreateFunil.vue";
import ModalAddPIntegration from "./Funil/ModalProductIntegration.vue";
import ModalAddIntegration from "./Funil/ModalIntegration.vue";
import htmlModal from "@/components/Formularios/htmlModal";
import EdicaoRapida from "@/components/Paginas/edicaoRapida";
//
export default {
  components: {
    Paginate,
    BaseHeader,
    ModalEditFunil,
    ModalCreateFunil,
    ModalAddPIntegration,
    ModalAddIntegration,
    htmlModal,
    EdicaoRapida,
    Chart3,
    DateRangePicker,
    Multiselect,
    ModalNewPage,
  },
  data() {
    return {
      loadingDomain: false,
      newPageName: '',
      // HIDDEN-FEATURE
      hide: false,
      selectedTab: "metricas",
      navigation: [
        { link: "Funis", to: this.$route.fullPath.replace(/\/\d+$/, "") },
        { link: "Funil atual", to: this.$route.fullPath },
      ],
      level: this.$store.getters.user.user.level,
      // PÁGINAS ->
      pagination_pages: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      graph: true,
      views: 0,
      lead: 0,
      sales: 0,
      last_views: 0,
      last_lead: 0,
      last_sales: 0,
      percentage_view: 0,
      percentage_lead: 0,
      percentage_sales: 0,
      periodo: {
        value: 7,
        name: "7 dias",
      },
      periodos: [
        { value: 7, name: "Últimos 7 dias" },
        { value: 15, name: "Últimos 15 dias" },
        { value: 30, name: "Últimos 30 dias" },
      ],
      // GDIGTAL PRODUCTS
      products: [],
      // INTEGRATION PRODUCTS ->
      total_integrations: 0,
      all_products_integrations: [],
      all_products_integrations_options: [],
      selected_product_integration: {
        product_name: "Selecione um produto",
        id: null,
      },
      status: true,
      editPage: null,
      all_pages: [],
      total_pages: 0,
      search_integration: "",
      search_pages: "",
      // FORMULARIOS ->
      status_form: true,
      pagination_forms: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      pagination_integration: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      selectedHtml: "",
      search_forms: "",
      total_forms: 0,
      selectedForms: null,
      all_forms: [],
      // FUNIL ->
      pagination_funnel: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      search_funnel: "",
      total_funnels: 0,
      selectedFunil: null,
      all_funnel: [],
      campanha: {},
      campaignExists: true,
      client: {
        width: 0,
      },
      loading: false,
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange1: {
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      totalPannelPages: 0,
    };
  },
  computed: {
    limitPages() {
      if (!this.$store.getters.recursos.hasOwnProperty('npages')) {
        return false
      }
      if (this.$store.getters.recursos.npages == 0) {
        return false
      }
      return this.totalPannelPages >= +this.$store.getters.recursos.npages
    },
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    filterNameCampanha() {
      var campanha = this.campanha;
      var title = "Gestão de funis - ";
      if (campanha && typeof campanha.title === "string") {
        var trimmedTitle = campanha.title.trim();
        var title2 =
          trimmedTitle.length > 37
            ? trimmedTitle.substring(0, 37) + "..."
            : trimmedTitle;

        var titleMobile =
          trimmedTitle.length > 22
            ? trimmedTitle.substring(0, 22) + "..."
            : trimmedTitle;

        if (this.isMobile) {
          return title + titleMobile;
        } else {
          return title + title2;
        }
      } else {
        return "";
      }
    },
    isMobile() {
      return this.client.width <= 768;
    },
    baseUrl() {
      var url = window.location.href.replace(/\/lista\/(\d+)/, "");
      return url;
    },
    filteredFunnel() {
      return !this.search_funnel.length
        ? this.all_funnel
        : this.all_funnel.filter(
            (item) =>
              item.id.toString().includes(this.search_funnel.trim()) ||
              item.title
                .toLowerCase()
                .includes(this.search_funnel.toLowerCase().trim())
          );
    },
    formsTagsConfigs(){
      return this.all_forms.map(form => {
        return {
          hasDistribuition: this.hasDistribuition(form.metas),
          hasCRMAutomation: this.hasCRMAutomation(form.metas),
          hasTagAutomation: this.hasTagAutomation(form.metas),
          hasThankYouPage: this.hasThankYouPage(form.metas),
          hasLeadScore: this.hasLeadScore(form.metas),
        };
      });
    },
  },
  methods: {
    limitPagesMessage() {
      if (!this.limitPages) {
        return
      }
      this.$grToast.toast(
        "Entre em contato com o suporte",
        {
          title: "Limite de páginas atingido",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
    },
    hasDistribuition(metas) {
      if (this.returnMetaValue(metas, 'lead_distribution') === 'true' && this.returnMetaValue(metas, 'users_list').length > 2) {
        return true
      }
      return false
    },
    hasCRMAutomation(metas) {
      if (this.returnMetaValue(metas, 'add_pipeline') === 'true' && this.returnMetaValue(metas, 'pipeline_id') && this.returnMetaValue(metas, 'board_id')) {
        return true
      }
      return false
    },
    hasTagAutomation(metas) {
      if (this.returnMetaValue(metas, 'tag_subs') || this.returnMetaValue(metas, 'tag_unsubs')) {
        return true
      }
      return false
    },
    returnMetaValue(metas, key) {
      if (!metas.length) {
        return ''
      }
      let meta = metas.find(meta => meta.meta_key == key)
      return meta ? meta.meta_value : ''
    },
    hasThankYouPage(metas) {
      if (this.returnMetaValue(metas, 'thank_you_page') && this.returnMetaValue(metas, 'thank_you_page').length > 1) {
        return true
      }
      return false
    },
    hasLeadScore(metas) {
      if (this.returnMetaValue(metas, 'leadscore') && this.returnMetaValue(metas, 'leadscore') !== '0') {
        return true
      }
      return false
    },
    setQuery(tab) {
      this.selectedTab = tab;
      this.$router.replace({ query: { tab: tab } });
    },
    getDashData() {
      serviceDashboard.read({ id: "info" }).then((resp) => {
        this.cards = resp;
      });
    },
    parseTitle(action) {
      switch (action) {
        case "sale_paid":
          return "Venda paga";
          break;
        case "sale_declined":
          return "Venda recusada";
          break;
        case "sale_refunded":
          return "Venda reembolsada";
          break;
        case "chargeback_processed":
          return "Chargeback realizado";
          break;
        case "sale_unpaid":
          return "Venda não paga";
          break;
        case "sale_awaiting_payment":
          return "Venda aguardando pagamento";
          break;
        case "unpaid_subscription":
          return "Assinatura não paga";
          break;
        case "paid_subscription":
          return "Assinatura paga";
          break;
        case "trial_subscription":
          return "Assinatura em trial";
          break;
        case "subscription_awaiting_payment":
          return "Assinatura aguardando pagamento";
          break;
        case "subscription_completed":
          return "Assinatura Finalizada";
          break;
        case "subscription_canceled":
          return "Assinatura Cancelada";
          break;
        case "checkout":
          return "Checkout";
          break;

        default:
          "Sem Tag";
          break;
      }
    },
    toPage_funnel(page) {
      "page -->", page;
      this.pagination_funnel.currentPage = page;
      this.getFunnel(this.search_funnel);
    },
    perPage_funnel(qtd) {
      this.pagination_funnel.currentPage = 1;
      this.pagination_funnel.perPage = qtd;

      this.getfunnel(this.search_funnel);
    },
    toPage_forms(page) {
      this.pagination_forms.currentPage = page;
      this.getForms(this.search_forms);
    },
    toPage_integration(page) {
      this.pagination_integration.currentPage = page;
      this.searchIntegration(this.search_forms);
    },
    perPage_integration(qtd) {
      this.pagination_integration.currentPage = 1;
      this.pagination_integration.perPage = qtd;

      this.searchIntegration(this.search_forms);
    },
    perPage_forms(qtd) {
      this.pagination_forms.currentPage = 1;
      this.pagination_forms.perPage = qtd;

      this.getForms(this.search_forms);
    },
    toPage_pages(page) {
      this.pagination_pages.currentPage = page;
      this.getPages(this.search_pages);
    },
    perPage_pages(qtd) {
      this.pagination_pages.currentPage = 1;
      this.pagination_pages.perPage = qtd;

      this.getPages(this.search_pages);
    },
    getProducts() {
      let data = {
        id: `campaign/${this.$route.params.id}`,
        status: 'active',
      };

      serviceProduto
        .createId(data)
        .then((resp) => {
          var products = [];
          resp.forEach((product) => {
            products.push({
              product_name: product.name,
              id: product.id,
              local: true,
            });
          });
          this.products = this.products.concat(products);
        })
        .finally(() => {});
    },
    changeStatus() {
      this.pagination_pages.currentPage = 1;
      this.getPages();
    },
    changeStatusForm() {
      this.pagination_forms.currentPage = 1;
      this.getForms();
    },
    redirectEditorEdit(page_id) {
      this.$router.replace({ query: null });
      EventBus.$emit("getStyleCss");
      // this.$router.push({name: 'EditorPage',
      // params: {
      // campaign_id: this.$route.params.id,
      // page_id: page.id,,
      // })
      var path = `${this.baseUrl}/${this.$route.params.id}/editor/${page_id}`;
      window.open(path, "_blank");
    },
    deletePage(item) {
      Vue.swal({
        title: "Deletar página",
        text: `Atenção, a ação a seguir não tem volta. Você tem certeza que deseja excluir de forma permanente a página selecionada?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          PaginaListService2.destroy({
            id:
              "delete/" +
              this.$store.getters.user.user.tenant_id +
              "/" +
              item.id,
          })
            .then(() => {
              this.loading = false;
              this.$grToast.toast("Página deletada com sucesso", {
                title: "Páginas",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.getPages();
            })
            .catch(() => {
              this.loading = false;
              this.$grToast.toast("Erro ao deletar página", {
                title: "Páginas",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    restaurarPagina(item) {
      if (this.limitPages) {
        this.limitPagesMessage()
        return
      }
      Vue.swal({
        title: "Restaurar página",
        text: `Deseja restaurar a página?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Restaurar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          PaginaListService2.createId({ id: `active/${item.id}` })
            .then(() => {
              this.loading = false;
              this.$grToast.toast("Página restaurada com sucesso", {
                title: "Páginas",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.getPages();
            })
            .catch(() => {
              this.loading = false;
              this.$grToast.toast("Erro ao restaurar página", {
                title: "Páginas",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    inactivePage(item) {
      Vue.swal({
        title: "Desativar página",
        text: `Deseja desativar a página?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Desativar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          PaginaListService2.createId({ id: `inactive/${item.id}` })
            .then(() => {
              this.$grToast.toast("Página desativada com sucesso", {
                title: "Páginas",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.getPages();
            })
            .catch(() => {
              this.$grToast.toast("Erro ao desativar página", {
                title: "Páginas",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.getPages();
              this.loading = false;
            });
        }
      });
    },
    clone(id) {
      if (this.limitPages) {
        this.limitPagesMessage()
        return
      }
      this.loading = true;
      PaginaListService2.createId({ id: `clone/${id}` })
        .then(() => {
          this.$grToast.toast("Página clonada com sucesso", {
            title: "Páginas",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.getPages();
        })
        .catch((error) => {
          this.$grToast.toast("Erro ao clonar página", {
            title: "Páginas",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    openModalEdicao(page) {
      this.editPage = page;
      this.$bvModal.show("edicao-rapida");
    },
    setAltImg(event) {
      event.target.src = this.$refs.invisible.src;
    },
    getMeta(metas, chave) {
      var result =
        metas.find((x) => x.meta_key == chave) &&
        metas.find((x) => x.meta_key == chave).meta_value
          ? metas.find((x) => x.meta_key == chave).meta_value
          : "";
      if (!result || result == "") {
        result = require("@/assets/img/icons/img_null.svg");
      }
      return result;
    },
    async openPage(e) {
      if(this.loadingDomain){
        return
      }
      let cardPage = this.$refs['pageId_'+`${e.id}`][0].dataset
      if(cardPage.pathRedirect !== 'false'){
        window.open(cardPage.pathRedirect, "_blank");
        return
      }      
      let idDomain = parseInt(e.metas.find((key)=> key.meta_key === "page_domain" ).meta_value)
      let path;
      this.loadingDomain = true

      path = `https://${this.$route.params.dynamicRoute}.${this.isGreenn ? process.env.GREENN_SALES_NUXT_DOMAIN  : 'gdigital.com.br'}/${e.path_name}`;

      if(idDomain){        
        await serviceDomain.read(idDomain).then((resp)=>{
          if(resp.status == 6 && resp.ssl_status == "ready"){
            path = `https://${resp.domain}/${e.path_name}`
          }
        }).catch((err)=>{
          window.open(path, "_blank");
        }).finally(()=>{
          this.loadingDomain = false
        })
      }else{
        this.loadingDomain = false
      }
      cardPage.pathRedirect = path
      window.open(path, "_blank");
    },
    percentage(partialValue, totalValue) {
      return ((100 * partialValue) / totalValue).toFixed(0);
    },
    debounce_pages: _.debounce(function () {
      this.pagination_pages.currentPage = 1;
      this.getPages(this.search);
    }, 500),
    getPages(search = null) {
      this.loading = true;
      this.pagination_pages.totalPages = 1;
      var data = {
        campaign_id: this.$route.params.id,
        page: this.pagination_pages.currentPage,
        status: this.status ? "save" : "trash",
      };

      if (this.search_pages) {
        data.search = this.search_pages;
      }

      servicePageCount.read('')
        .then(resp => {
          this.totalPannelPages = resp.pagesCount
        })

      PaginaListService.create(data)
        .then((resp) => {
          "paginas -->", resp;
          // Páginas
          this.all_pages = resp.data;
          this.total_pages = resp.total;
          this.pagination_pages.totalPages = resp.last_page;
          this.getCoversions();
        })
        .finally(() => {
          this.loading = false;
          if (this.$route.query.tab) {
            if (this.$route.query.tab === "paginas") {
              this.selectedTab = this.$route.query.tab;
              this.$router.replace({ query: null });
            }
          }
        });
    },
    getCoversions() {
      var paginas = this.all_pages;
      for (let i = 0; i < paginas.length; i++) {
        const pagina = paginas[i];
        PaginaListService2.read({ id: "/info/" + pagina.id }).then(
          (response) => {
            Vue.set(this.all_pages[i], "visitas", response.views);
            Vue.set(this.all_pages[i], "convercao", response.conversions);
          }
        );
      }
    },
    debounce_forms: _.debounce(function () {
      this.pagination_forms.currentPage = 1;
      this.getForms(this.search_forms);
    }, 500),
    debounce_integration_product: _.debounce(function () {
      // this.pagination_forms.currentPage = 1;
      this.searchIntegration(this.search_forms);
    }, 500),
    searchIntegration(search = null) {
      // this.loading = true;
      this.pagination_integration.totalPages = 1;

      var data = {
        id: `search?search=${this.search_integration}`,
      };

      serviceProductIntegration.read(data).then((resp) => {
        this.all_products_integrations = resp;
        this.total_integrations = resp.resp.length;
        // this.loading = false;
      });
    },

    getForms(search = null) {
      this.loading = true;
      this.pagination_forms.totalPages = 1;
      var data = {
        campaign_id: this.$route.params.id,
        page: this.pagination_forms.currentPage,
        status: this.status_form ? "active" : "inactive",
      };
      if (this.search_forms) {
        data.search = this.search_forms;
      }
      serviceForm
        .search(data)
        .then((resp) => {
          // Formularios
          this.all_forms = resp.data;
          this.total_forms = resp.total;
          this.pagination_forms.totalPages = resp.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    restaurarForm(id) {
      serviceForm
        .createId({ id: `active/${id}` })
        .then(() => {
          this.$grToast.toast("Formulário ativado com sucesso", {
            title: "Formulário",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch(() => {
          this.$grToast.toast("Erro ao ativar formulário", {
            title: "Formulário",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.getForms();
          this.loading = false;
        });
    },
    inactiveForm(id) {
      Vue.swal({
        title: "Desativar formulário",
        text: `Deseja desativar o formulário?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Desativar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          serviceForm
            .createId({ id: `inactive/${id}` })
            .then(() => {
              this.$grToast.toast("Formulário inativado com sucesso", {
                title: "Formulário",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch(() => {
              this.$grToast.toast("Erro ao desativar formulário", {
                title: "Formulário",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.getForms();
              this.loading = false;
            });
        }
      });
    },
    getProductIntegration() {
      serviceProductIntegration.search({campaign_id: this.$route.params.id}).then((resp) => {
        this.all_products_integrations = resp.products;

        this.products = Object.values(
          resp.products.reduce((acc, obj) => {
            acc[obj.product_name] = acc[obj.product_name] || obj;
            return acc;
          }, {})
        );
        this.products.unshift({
          product_name: "Selecione um produto",
          id: null,
        });

        if (resp.products.length > 0) {
          this.total_integrations = resp.products.length;
        } else {
          this.total_integrations = 0;
        }
      });
    },
    deletarHardForm(id) {
      Vue.swal({
        title: "Gestão de campanhas",
        text: `Deseja realmente deletar o formulário?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: `/delete/${id}`,
          };
          serviceForm
            .destroy(data)
            .then(() => {
              this.loading = false;
              this.$grToast.toast("Formulário deletado com sucesso", {
                title: "Gestão de formulários",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.getForms();
            })
            .catch(() => {
              this.$grToast.toast("Erro ao deletar formulário", {
                title: "Gestão de formulários",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    deleteIntegration(id) {
      serviceProductIntegration
        .destroy(id)
        .then((resp) => {
          this.$grToast.toast("Formulário deletado com sucesso", {
            title: "Gestão de formulários",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.getProductIntegration();
        })
        .catch((r) => {
          this.$grToast.toast("Erro ao deletar Integração", {
            title: "Integração de Produtos",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    deleteFunnel(id) {
      Vue.swal({
        title: "Gestão de campanhas",
        text: `Deseja realmente deletar o funil ?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          serviceFunnel
            .destroy(id)
            .then((resp) => {
              this.$grToast.toast("Funil removido com sucesso", {
                title: "Gestão de campanhas",
                variant: "info",
                autoHideDelay: 300,
                appendToast: true,
              });
              return;
            })
            .catch((error) => {
              this.$grToast.toast("Erro ao remover funil", {
                title: "Gestão de campanhas",
                variant: "danger",
                autoHideDelay: 300,
                appendToast: true,
              });
              return;
            })
            .finally(() => {
              this.getFunnel();
            });
        }
      });
    },
    debounce_funnel: _.debounce(function () {
      this.pagination_funnel.currentPage = 1;
      this.getFunnel(this.search_funnel);
    }, 500),
    getFunnel(search = null) {
      this.loading = true;
      this.pagination_funnel.totalPages = 1;

      var data = {
        campaign_id: this.$route.params.id,
        page: this.pagination_funnel.currentPage,
      };
      if (this.search_funnel) {
        data.search = this.search_funnel;
      }

      serviceFunnel
        .search(data)
        .then((resp) => {
          "funnels -->", resp;
          // FUNIL
          this.all_funnel = resp.data;
          this.total_funnels = resp.total;
          this.pagination_funnel.totalPages = resp.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    routeEtapasCampanha() {
      var id = this.$route.params.id;
      this.$router.push(`/funis/etapas/${id}`);
    },

    fetchLead(id) {
      this.loading = true;
      serviceCampaign
        .read(id)
        .then((resp) => {
          this.campanha = resp;

          if (!this.campanha.id) {
            this.campaignExists = false;
            this.$grToast.toast("Essa campanha não existe", {
              title: "Campanha",
              variant: "danger",
              autoHideDelay: 300,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          this.$grToast.toast("Erro ao encontrar campanha", {
            title: "Campanha",
            variant: "danger",
            autoHideDelay: 300,
            appendToast: true,
          });
          // (err);
          setTimeout(() => {
            this.$router.push("/dynamicRoute/funis/lista");
          }, 2000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    routeCreateCamapanha() {
      this.$router.push({ name: "CampanhasCriar" });
    },
    openModal2(data, form) {
      this.selectedHtml = form;
      this.$bvModal.show(data);
    },
    openModal(data, funnel) {
      this.selectedFunil = funnel;
      this.$bvModal.show(data);
    },
    openModal3(data) {
      this.$bvModal.show(data);
    },

    createMetrics(e = 7) {
      var data = {
        id: "metrics/" + this.$route.params.id,
        start_date: moment().subtract(e, "days").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      };

      if (
        this.selected_product_integration &&
        this.selected_product_integration.product_greenn_id
      ) {
        data.product_greenn_id =
          this.selected_product_integration.product_greenn_id;
      } else if (
        this.selected_product_integration &&
        this.selected_product_integration.local
      ) {
        data.local_product_id = this.selected_product_integration.id;
      }

      serviceCampaign.createId(data).then((resp) => {
        this.views = resp.views;
        this.lead = resp.leads;
        this.sales = resp.sales;
        data.end_date = data.start_date;
        data.start_date = moment()
          .subtract(e * 2, "days")
          .format("YYYY-MM-DD HH:mm:ss");
        serviceCampaign.createId(data).then((resp2) => {
          this.last_views = resp2.views;
          this.last_lead = resp2.leads;
          this.last_sales = resp2.sales;
          var percentageV = (
            ((this.views - this.last_views) /
              (this.last_views == 0 ? this.views : this.last_views)) *
            100
          ).toFixed(2);
          // this.percentage_view = percentageV;
          var percentageL = (
            ((this.lead - this.last_lead) /
              (this.last_lead == 0 ? this.lead : this.last_lead)) *
            100
          ).toFixed(2);
          // this.percentage_lead = percentageL;
          var percentageS = (
            ((this.sales - this.last_sales) /
              (this.last_sales == 0 ? this.sales : this.last_sales)) *
            100
          ).toFixed(2);

          if (this.views) {
            this.percentage_view = percentageV;
          } else {
            this.percentage_view = 0;
          }

          if (this.leads) {
            this.percentage_lead = percentageL;
          } else {
            this.percentage_lead = 0;
          }

          if (this.sales) {
            this.percentage_sales = percentageS;
          } else {
            this.percentage_sales = 0;
          }
        });
      });
    },
  },
  mounted() {
    this.createMetrics();
    if (this.$route.query.tab) {
      if (this.$route.query.tab === "paginas") {
        return;
      }
      this.selectedTab = this.$route.query.tab;
      this.$router.replace({ query: null });
    }
  },
  created() {
    this.getProductIntegration();
    this.fetchLead(this.$route.params.id);
    this.handleResize();
    this.getPages();
    this.getForms();
    this.getFunnel();
    this.getProducts();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss">
.forms-cards-wrapper {
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(362px, 1fr));
  gap: 15px;
  > div {
    border: 1px solid var(--white-medium);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .form-preview {
      background-color: var(--greenn2);
      padding: 30px 20%;
      height: 200px;
      max-height: 200px;
      overflow: hidden;
      pointer-events: none;
      label {
        line-height: 1.2;
        margin-top: 12px;
      }
      ul, ol, li {
        list-style-type: none !important;
        padding: 0;
      }
      > div {
        zoom: 0.8;
      }
    }
    .form-edit {
      position: absolute;
      z-index: 1;
      height: 200px;
      width: 100%;
      top: 0;
      left: 0;
      background-color: var(--greenn2);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: .3s;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    > span {
      position: absolute;
      color: var(--greenn);
      font-size: 12px;
      top: 15px;
      right: 15px;
      line-height: 1;
      user-select: none;
    }
    > div:last-child {
      padding: 20px;
      gap: 15px;
      display: grid;
      grid-template-columns: calc(100% - 73px) 58px;
      align-items: center;
      >div:first-child {
        max-width: 100%;
        width: 100%;
        .form-name {
          white-space: nowrap;
          display: inline-block;
          max-width: 100%;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
        .form-date {
          font-size: smaller;
          display: block;
          line-height: 1;
          color: var(--white-dark);
        }
      }
      >div:last-child {
        display:flex;
        gap: 10px;
        height: fit-content;
        svg {
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.form-tags {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 2;
  > div {
    background-color: var(--greenn-transparent);
    padding: 3px 5px 9px 5px;
    clip-path: polygon(0 100%, 0 0%, 100% 0%, 100% 100%, 50% calc(100% - 5px));
    > svg {
      fill: var(--greenn);
    }
  }
}
// HIDDEN-FEATURE
.in-progress {
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  display: grid;
  background-color: #fff5;
  backdrop-filter: blur(10px);
  align-content: center;
  justify-items: center;
  position: absolute;
  z-index: 1000;
  left: -10px;
  top: -10px;
  animation: slideIn .5s ease-in-out forwards;
  h3 {
    text-align: center;
    font-size: 18px;
  }
}
.inputSearch {
  @media (max-width: 768px) {
    width: 100%;
  }
}

.container-user {
  @media (max-width: 768px) {
    border: none !important;
    padding: 0px !important;
  }
}
.unic_list {
  .Table-body-container:nth-child(4n) .Table-body {
    background-color: #fafafb;
  }

  .Table-body-container:nth-child(4n-1) .Table-body {
    background-color: #fff;
  }

  .Table-body-container:nth-child(4n-2) .Table-body {
    background-color: #fafafb;
  }

  .Table-body-container:nth-child(4n-3) .Table-body {
    background-color: #fff;
  }

  .paginas-descricao-opt {
    color: var(--greenn);
  }

  .paginas-descricao-titulo {
    font-weight: 600;
    font-size: 18px;
    color: var(--gray01);
    width: 220px;
    white-space: nowrap;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    user-select: none;

    &::-webkit-scrollbar {
      width: 20px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.123) !important;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.123) !important;
    }
  }

  .paginas-descricao {
    padding: 30px 30px;
  }

  .paginas-conteudo-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .paginas-conteudo {
    border: 1px solid #ededf0;
    border-radius: 10px;
    cursor: pointer;
    @media (max-width: 430px) {
      margin: auto;
    }
  }

  .img-conteudo {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 217.42px;
    border-radius: 5px 5px 0 0;
  }

  .img-icon {
    width: 12px;
    height: 13.5px;
  }

  .display-flex2 {
    display: grid;
    grid-template-columns: 1fr 80px;
  }

  .display-flex {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }

  //
  .pd {
    padding-right: 0;
    padding-left: 0;
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    transition: all 0.5s ease;
    opacity: 0;
    transform: translateX(30px);
    transform: translateY(-30px);
  }

  .search {
    left: 20px !important;
  }

  .row {
    margin: 0px;
  }

  .seta3-mobile {
    position: absolute;
    height: 40px;
    width: 50px;
    background-color: #012c23;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 10px 10px;
    top: 395px;
    left: 130px;
    z-index: 3;

    span {
      color: #ffffff !important;
      font-weight: 450 !important;
      font-size: 16px !important;
    }

    &::after {
      position: absolute;
      right: 15px;
      bottom: -29px;
      content: "";
      width: 0px;
      height: 0px;
      border-top: 21px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid #012c23;
      transform: rotate(90deg);
    }
  }

  .seta2-mobile {
    position: absolute;
    height: 40px;
    width: 50px;
    background-color: #012c23;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 10px 10px;
    top: 255px;
    left: 130px;
    z-index: 3;

    span {
      color: #ffffff !important;
      font-weight: 450 !important;
      font-size: 16px !important;
    }

    &::after {
      position: absolute;
      right: 15px;
      bottom: -29px;
      content: "";
      width: 0px;
      height: 0px;
      border-top: 21px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid #012c23;
      transform: rotate(90deg);
    }
  }

  .seta1-mobile {
    position: absolute;
    height: 40px;
    width: 50px;
    background-color: #012c23;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 10px 10px;
    top: 115px;
    left: 130px;
    z-index: 3;

    span {
      color: #ffffff !important;
      font-weight: 450 !important;
      font-size: 16px !important;
    }

    &::after {
      position: absolute;
      right: 15px;
      bottom: -29px;
      content: "";
      width: 0px;
      height: 0px;
      border-top: 21px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid #012c23;
      transform: rotate(90deg);
    }
  }

  .graph-number-mobile {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px 0 0 0;

    span {
      color: #012c23 !important;
      font-weight: 500 !important;
      font-size: 16px !important;
    }
  }

  .card-content-mobile {
    position: relative;
    background-color: #ffffff;
    border: 1.5px solid #eeeeee;
    box-shadow: 0px 4px 10px 2px rgba(1, 41, 37, 0.116);
    border-radius: 10px;
  }

  .card-number-mobile {
    background-color: var(--greenn);
    box-shadow: 0px 2px 5px 2px rgba(1, 41, 37, 0.116);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    padding: 5px 11px 5px 11px;
  }

  .card-and-title-mobile {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #abacad67;

    span {
      color: #012c23 !important;
      font-weight: 550 !important;
      font-size: 16px !important;
    }
  }

  .card-content-mobile {
    padding: 20px;
  }

  .container-graficos {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .seta1 {
    position: absolute;
    height: 2vw;
    width: 4.2vw;
    background-color: #012c23;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 3;
    border-radius: 5px 5px 5px 5px;

    span {
      color: #ffffff !important;
      font-weight: 450 !important;
      font-size: 1vw !important;
    }
  }

  .seta1::after {
    position: absolute;
    right: -0.8vw;
    content: "";
    width: 0;
    height: 0;
    border-top: 1.035vw solid transparent;
    border-bottom: 1.035vw solid transparent;
    border-left: 1vw solid #012c23;
  }

  .new-graphic {
    width: 100%;
    height: 15vw;
    position: relative;
  }

  .card-content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 12%;
  }

  .card-and-title {
    width: 100%;
    display: flex;
    gap: 1vw;
    align-items: center;
    padding-bottom: 0.8vw;
    border-bottom: 1px solid #abacad67;

    .card-title {
      margin: 0;
      padding: 0;

      span {
        color: #012c23 !important;
        font-weight: 550 !important;
        font-size: 1vw !important;
      }
    }
  }

  .graph-number {
    width: 100%;
    height: calc(100% - 3.2vw);
    display: flex;
    justify-content: center;
    align-items: flex-end;

    span {
      color: #012c23 !important;
      font-weight: 500 !important;
      font-size: 1.1vw !important;
    }
  }

  @media screen and (min-width: 1500px) {
    .graph-number {
      width: 100%;
      height: calc(100% - 48px);
      display: flex;
      justify-content: center;
      align-items: flex-end;

      span {
        color: #012c23 !important;
        font-weight: 500 !important;
        font-size: 16px !important;
      }
    }

    .card-graph {
      height: 165px !important;
    }

    .seta1 {
      height: 30px;
      width: 63px !important;

      span {
        color: #ffffff !important;
        font-size: 15px !important;
      }
    }

    .seta1::after {
      right: -12px;
      border-top: 15.525px solid transparent;
      border-bottom: 15.525px solid transparent;
      border-left: 15px solid #012c23;
    }

    .card-number {
      width: 36px !important;
      height: 36px !important;

      span {
        font-size: 15.15px !important;
      }
    }
  }

  #sell_type {
    display: flex;
    position: relative;
    left: -5px;
    width: 70px;
    padding: 0px 0px 0px 5px !important;
    font-display: 10px;
    height: 24px !important;
  }

  .card-number {
    // background: linear-gradient(305deg, #025847 0%, rgba(9,121,101,1) 35%, rgb(6, 199, 128) 100%);
    background-color: var(--greenn);
    box-shadow: 0px 2px 5px 2px rgba(1, 41, 37, 0.116);
    width: 2.4vw;
    height: 2.4vw;
    border-radius: 15%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: white !important;
      font-weight: 650 !important;
      font-size: 1.1vw;
    }
  }

  .graph-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.4vw;
  }

  .card-graph {
    position: relative;
    background-color: #ffffff;
    border: 1.5px solid #eeeeee;
    box-shadow: 0px 4px 10px 2px rgba(1, 41, 37, 0.116);
    width: 22%;
    min-width: 22%;
    height: 11vw;
    // border-radius: 2vw 0;
    border-radius: 10px;
  }

  .graph-line {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .repeat-one {
    width: 100%;
    height: 8px;
    background-image: repeating-linear-gradient(
      45deg,
      var(--greenn) 00,
      var(--greenn) 00 10px,
      var(--greenn) 10px,
      var(--greenn) 20px
    );
  }

  .repeat-two {
    width: 100%;
    height: 8px;
    background-image: repeating-linear-gradient(
      135deg,
      var(--greenn) 00,
      var(--greenn) 00 10px,
      var(--greenn) 10px,
      var(--greenn) 20px
    );
  }

  .title-graph {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  .flex-graph {
    display: flex;
    width: 95%;
    justify-content: space-between;
    position: relative;
    padding-top: 40px;
    margin: 0 auto;
  }

  .pentagon {
    position: relative;
    width: 342px;
    transform: rotate(90deg);
    height: 425px;
    background: var(--greenn-light);
    clip-path: polygon(50% 0, 100% 54%, 100% 100%, 0% 100%, 0 54%);
  }

  .triangle {
    position: relative;
    background-color: var(--greenn-light);
    text-align: left;
    width: 100%;
  }

  .triangle:before,
  .triangle:after {
    content: "";
    position: absolute;
    background-color: inherit;
  }

  .triangle,
  .triangle:before,
  .triangle:after {
    width: 15em;
    height: 15em;
    border-top-right-radius: 30%;
  }

  .triangle {
    transform: rotate(270deg) skewX(-30deg) scale(1, 0.866);
  }

  .triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }

  .triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }

  .input-busca {
    width: 100%;
    height: 50px !important;
    background: #ffffff;
    /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
    border-radius: 10px;
    padding: 20px 30px;
    padding-left: 45px !important;
    border: none;
    font-family: Montserrat;
    font-weight: normal;
    color: #81858e;
    font-size: 14px;
    transition: 0.5s;

    margin-bottom: 0 !important;
  }

  .input-busca:hover,
  .input-busca:focus {
    border-color: #ededf0 !important;
    font-weight: normal !important;
  }

  .header-table {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 0;
    padding-top: 15px;
  }

  .search {
    position: absolute;
    left: 37px;
    top: 17px;
    width: 14.7px;
    height: 14.7px;
    z-index: 998;
  }

  .container-pesquisa {
    position: relative;
  }

  @media screen and (max-width: 1300px) {
    .search {
      left: 30px;
    }
  }

  .container-pesquisa {
    text-align: right;
    z-index: 1;
  }

  .btn-table {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: rgba(237, 237, 240, 0.5);
    outline: none;
  }

  .btn-table:active {
    background: rgba(237, 237, 240, 0.5);
    border: none;
  }

  .btn-table img {
    filter: invert(50%);
  }

  .btn-table:first-child {
    background: var(--greenn2);
    margin-right: 15px;
  }

  .btn-table:first-child:active {
    background: var(--greenn2);
    border: none;
  }

  .btn-table:first-child img {
    filter: invert(0);
  }

  .btn-table:nth-child(3) {
    margin-left: 15px;
    margin-right: 20px;
  }

  .header-table {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    padding-top: 15px;
  }

  .flex-sale {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .indicadores {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 30px;

    .indicador {
      background: #ffffff;
      border: 0.5px solid #ededf0;
      border-radius: 10px;
      height: 145px;
      padding: 25px 35px;

      h3 {
        font-weight: 600;
        font-size: 20px;
        color: var(--greenn);
        margin-bottom: 12px;
      }

      h2 {
        font-weight: 600;
        font-size: 20px;
        color: var(--gray01);
      }
    }

    .flex-values {
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 400;
        font-size: 16px;
        color: var(--gray01);
      }
    }
  }

  .graphic-metrics {
    border-top: solid var(--white-medium) 1px;
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 7px;
    padding: 22px 0px 0px 0px;
    border-radius: 10px;

    .card-metrics {
      width: 100%;
      background-color: var(--white-medium);
      border-radius: 10px;
      padding: 25px 30px 31px 37px;

      .header-card {
        font-size: 14px;
        font-family: Montserrat;

        .text {
          margin-bottom: 10px;
        }

        .main-text {
          display: flex;
          justify-content: space-between;
          -webkit-box-align: justify;
          align-items: center;

          .por-text {
            background-color: var(--greenn2);
            padding: 2px 5px;
            border-radius: 1.5px;

            span {
              color: var(--greenn);
            }
          }

          .por-text2 {
            background-color: #dc354524;
            padding: 2px 5px;
            border-radius: 1.5px;

            span {
              color: var(--red);
            }
          }

          p {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 0px;
          }
        }

        .footer {
          display: -webkit-box;
          gap: 10px;
          margin-top: 20px;
          height: 50px;

          p {
            font-size: 15px;
            margin-bottom: 0px;
            color: var(--gray04);
          }
        }

        .footer-access {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          .text-access {
            font-size: 15px;
            margin-bottom: 0px;
            color: var(--gray04);
            align-items: center;
          }
        }
      }
    }
  }

  .graphic-metrics-mobile {
    border-top: solid var(--white-medium) 1px;
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 22px 0px 0px 0px;
    border-radius: 10px;

    .card-metrics {
      width: 100%;
      background-color: var(--white-medium);
      border-radius: 10px;
      padding: 25px 30px 31px 37px;

      .header-card {
        font-size: 14px;
        font-family: Montserrat;

        .text {
          margin-bottom: 10px;
        }

        .main-text {
          display: flex;
          justify-content: space-between;
          -webkit-box-align: justify;
          align-items: center;

          .por-text {
            background-color: var(--greenn2);
            padding: 2px 5px;
            border-radius: 1.5px;

            span {
              color: var(--greenn);
            }
          }

          p {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 0px;
          }
        }

        .footer {
          display: -webkit-box;
          gap: 10px;
          margin-top: 10px;

          p {
            font-size: 15px;
            margin-bottom: 0px;
            color: #81858e;
          }
        }

        .footer-access {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          .text-access {
            font-size: 15px;
            margin-bottom: 0px;
            color: #81858e;
            align-items: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .indicadores {
      grid-template-columns: 1fr;
    }
  }
}

.containerCampaignNoExisting {
  margin-top: 1.5rem;

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .viewAllCampaign {
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 40px 65px;
    border-radius: 4px;
    background-color: #ededf0;

    p {
      font-size: 14px;
    }
  }
}

.graph {
  margin-top: 50px;
  margin-bottom: 75px;
  .title-graph {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
}
</style>
