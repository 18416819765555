<template>
  <div style="height: 350px" class="w-100">
    <div class="container-loading w-100" v-show="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-show="!loading" style="height: 350px" class="w-100">
      <apexchart
        ref="chart3"
        type="area"
        height="400"
        :options="chartOptions"
        :series="chartSeries"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import CampaignService from "@/services/resources/CampaignService";

const serviceCampaign = CampaignService.build();

export default {
  name: "chart3",
  props: ["event", "product"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading: true,
      result: 0,
      firstLoad: true,
      chartOptions: {
        colors: ["#004392", "#4ea934", "#ff9e44"],
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 9,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          categories: [],
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      },
      chartSeries: [
        {
          name: "Visualizações",
          data: [],
        },
        {
          name: "Conversões",
          data: [],
        },
        {
          name: "Vendas",
          data: [],
        },
      ],
    };
  },
  mounted() {
    this.getMetrics();
  },
  methods: {
    async getMetrics() {
      if(this.firstLoad){
        this.loading = true;
      }

      try {
        const data = {
          id: "metrics/graph/" + this.$route.params.id,
          start_date: moment()
            .subtract(this.event, "days")
            .format("YYYY-MM-DD HH:mm:ss"),
          end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        };

        if (this.product && this.product.product_greenn_id) {
          data.product_greenn_id = this.product.product_greenn_id;
        }else if( this.product &&
        this.product.local){
          data.local_product_id = this.product.id
      }

        const resp = await serviceCampaign.createId(data);
        await this.updateChartData(resp);

        this.$refs.chart3.refresh()
      } catch (error) {
        console.error("Error fetching metrics:", error);
      } finally {
        this.loading = false;
      }
    },
    updateChartData(result) {
      const date = [];
      const views = [];
      const conversions = [];
      const sales = [];
      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        date.push(element.y);
        views.push(element.views);
        conversions.push(element.conversions);
        sales.push(element.sales);
      }

      this.chartOptions.xaxis.categories = date;
      this.chartSeries[0].data = views;
      this.chartSeries[1].data = conversions;
      this.chartSeries[2].data = sales;
      this.firstLoad = false;
    },
  },
  watch: {
    event(e) {
      this.getMetrics(e);
    },
    product() {
      this.getMetrics(this.event);
    },
    '$route.params.id': {
      immediate: true,
      handler() {
        this.getMetrics();
      },
    },
  },
};
</script>

<style scoped>
.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}
</style>
