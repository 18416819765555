<template>
  <b-modal
    name="Modal-integration"
    id="Modal-integration"
    idModal="Modal-integration"
    title="Selecione a nova integração"
    centered
    hide-footer
    scrollable
  >
    <div class="card-integration" @click="$emit('openModal3', 'Modal-add-integration'), $bvModal.hide('Modal-integration')">
      <div class="icon-integration">
        <img src="@/assets/img/greenn.png" alt="Logo do greenn" >
      </div>
      <div class="text-integration">
        <p class="title-integration">Greenn</p>
        <p class="description-integration">Uma plataforma de pagamentos simples e inovadora.</p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.card-integration {
  display: flex;
  border-radius: 10px;
  border: 0.5px solid #ededf0;
  padding: 20px 25px;
  gap: 20px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    border-color: var(--greenn);
  }
  .icon-integration {
    border-radius: 10px;
    background-color: #ededf0;
    height: 80px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 70%;
      max-width: 70%;
    }
  }
  .text-integration {
    display: Grid;
    gap: 10px;
    min-height: 100%;
    align-content: center;
    p {
      margin-bottom: 0;
    }
    .description-integration {
      font-size: 14px;
    }
    .title-integration {
      font-weight: 600;
    }
  }
}
</style>
